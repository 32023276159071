import React from 'react';
import styles from "./MyCard.module.css";

const MyCard = (props) => {

    const handleClick=()=>{
        if (props.onClick){
            props.onClick()
        }
    }
    return <div className={`${styles.card} ${props.className}`} onClick={handleClick} style={props.style}>
                {props.children}
            </div>
};

export default MyCard;