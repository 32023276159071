import {useReducer} from "react";

const initialInputState = {value: "", isTouched: false};
const inputStateReducer = (state, action) => {
    if (action.type === "BLUR") {
        return {value: action.value, isTouched: true};
    }
    if (action.type === "TOUCH") {
        return {value: state.value, isTouched: true};
    }
    return initialInputState;
};

const useInput = (validateValue, initialValue) => {
    const [inputState, dispatch] = useReducer(
        inputStateReducer,
        {value: initialValue, isTouched: false}
    );

    const valueIsValid = validateValue(inputState.value);
    const hasError = !valueIsValid && inputState.isTouched;


    const inputBlurHandler = (event) => {
        dispatch({type: "BLUR", value: event.target.value});
    };
    //touch to enable validation
    const touchField = () => {
        dispatch({type: "TOUCH"});
    };

    return {
        value: inputState.value,
        isValid: valueIsValid,
        hasError,
        touchField,
        inputBlurHandler,
    };
};
export default useInput;