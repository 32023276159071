import React from 'react';
import {Link} from "react-router-dom";
import {MixedWidget4} from "./MixedWidjet4";
import {AdvanceTablesWidget1} from "./AdvancedTablesWidget1";
import {StatsWidget10} from "./StatsWidget10";
import {TilesWidget12} from "./TilesWidget12";
import {useSelector} from "react-redux";
import isAuthorized from "../../helpers/AuthorizedFunction";
import {WidgetPublicPlans} from "./WidgetPublicPlans";
import {WidgetPatients} from "./WidgetPatients";
import TilesInfo from "./DashboardTherapist/TilesInfo";

const DashboardTherapist = () => {
    const {user} = useSelector((state) => state.auth);
    return (
        <>
            {/*{user && user.attributes && user.attributes.type==="therapist" &&*/}
            {isAuthorized(["admin"]) && //therapist
            <div className="d-flex flex-row flex-wrap justify-content-around ">
                <Link className={'btn btn-dark m-2'} to="/exercise/speech">
                    Ασκήσεις Ομιλίας
                    {/*<ExerciseCard imgUrl='/media/images/omilia.png' title='' className=""/>*/}
                </Link>
                <Link className={'btn btn-dark m-2'} to="/exercise/language">
                    Ασκήσεις Κατανόησης
                    {/*<ExerciseCard imgUrl='/media/images/glossa.png' title='Ασκήσεις Γλώσσας/Κατανόησης'/>*/}
                </Link>
                <Link className={'btn btn-dark m-2'} to="/exercise/language2">
                    Ασκήσεις Παραγωγής
                    {/*<ExerciseCard imgUrl='/media/images/glossa.png' title='Ασκήσεις Γλώσσας/Παραγωγή Λέξης'/>*/}
                </Link>
                <Link className={'btn btn-secondary m-2'} to="/exercise/plans">
                    Ασκήσεις Πλάνων
                    {/*<ExerciseCard imgUrl='/media/images/omilia.png' title='Ασκήσεις Πλάνων' className=""/>*/}
                </Link>
                {isAuthorized(["admin"]) && <Link className={'btn btn-outline-danger  m-2'}
                                                  to={"/exercise/plans/45"}>
                    Plan 45 / θεραπεια 1
                </Link>}
            </div>}
            {/* begin::Row */}
            <div className="row mt-5">
                <TilesInfo/>
            </div>
            {/* begin::Row */}
            <div className="row mt-5">
                {/* PATIENTS */}
                <div className="col-lg-6 col-xl-6">
                    <WidgetPatients className="card-stretch gutter-b"/>
                </div>
                {/* PLANS */}
                <div className="col-lg-6 col-xl-6">
                    <WidgetPublicPlans className="card-stretch gutter-b"/>
                </div>
                {/*<div className="col-xl-8">*/}
                {/*    <BaseTablesWidget6 className="gutter-b" />*/}
                {/*</div>*/}
            </div>

            {isAuthorized(["admin"]) &&
                <div className="row">
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-sm-6">

                            <TilesWidget12 className="gutter-b"
                                           iconColor="success"
                                           title="Συνολο Ασκήσεων"
                                           content={120}
                            />
                        </div>
                        <div className="col-sm-6">
                            <TilesWidget12 className="gutter-b"
                                           iconColor="danger"
                                           title="Συνολο Ασκήσεων"
                                           content={120}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className="col-lg-6 col-xl-8">*/}
                {/*    <WidgetPatients className="card-stretch gutter-b"/>*/}
                {/*</div>*/}
                <div className="col-lg-6">
                    <MixedWidget4 className="gutter-b card-stretch"/>
                </div>
                {/*<div className="col-lg-6 col-xl-8">*/}
                {/*    <AdvanceTablesWidget1 className="card-stretch gutter-b"/>*/}
                {/*</div>*/}

                {/*<div className="col-xl-4">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-xl-12">*/}
                {/*            <StatsWidget11*/}
                {/*                className="gutter-b"*/}
                {/*                symbolShape="circle"*/}
                {/*                baseColor="danger"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="col-xl-4">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-xl-12">*/}
                {/*            <StatsWidget10*/}
                {/*                className="gutter-b"*/}
                {/*                symbolShape="circle"*/}
                {/*                baseColor="info"*/}
                {/*            />*/}
                {/*            <StatsWidget11*/}
                {/*                className="gutter-b"*/}
                {/*                symbolShape="circle"*/}
                {/*                baseColor="danger"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>}
            {/* end::Row */}
        </>
    );
};

export default DashboardTherapist;
