import Keycloak from "keycloak-js";

const keycloakConfig = {
    // url: "http://localhost:8080/auth",
    url: "https://auth.pikei.dev/",
    realm: 'planv',
    clientId: 'react-app-id'
    // url: "http://localhost:8888/auth",
    // realm: 'PlanV',
    // clientId: 'react-app-id'
};
const keycloak = new Keycloak(keycloakConfig);
console.log("new Keycloak: ",keycloak)

export default keycloak;
