import React from "react";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "../../keycloak";
import {useDispatch} from "react-redux";
import * as auth from "../modules/Auth/_redux/authRedux";


const KeycloakProvider = (props) => {
    const dispatch = useDispatch();

    const onKeycloakEvent = (event, error) => {
        const now = new Date();
        console.log(now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds() + ' onKeycloakEvent:', event, error)
    }
    const onKeycloakTokens = (tokens) => {
        console.log('onKeycloakTokens', tokens)
        if(tokens && tokens.token){
            dispatch(auth.actions.refreshToken(tokens.token));
        }
    }
    return (
        <ReactKeycloakProvider authClient={keycloak}
                               onEvent={onKeycloakEvent}
                               onTokens={onKeycloakTokens}>
            {props.children}
        </ReactKeycloakProvider>
    );
};

export default KeycloakProvider;