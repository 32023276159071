import React from 'react';

// const Input = React.forwardRef((props, ref) => {
// <input ref={ref}  {...props.input} />

const DropdownList = props => {
    // console.log(props.value);

    // console.log(props.items, props.name)
    const selectedOption = props.value && props.items && props.items.find(item => (item.id/1 === props.value/1))
    console.log({selectedOption})
    return (
        <div className="form-group mb-3">
            <label className="form-label" htmlFor={props.id}>
                {props.label}
            </label>
            {Array.isArray(props.items) &&
            <select id={props.id} name={props.name} className="form-control" onBlur={props.onBlur} >
                {/* Selected Option */}
                {selectedOption ?
                    <option key={selectedOption.id} value={selectedOption.id}>{selectedOption.label}</option> : ''}

                <option value={0}/>
                {/* Rest Available Option */}
                {props.items && props.items.map(item => (item.id/1 !== props.value/1) ?
                    <option key={item.id} value={item.id}>{item.label}</option> : '')}

            </select>}
        </div>
    );
};
export default DropdownList;
