import axios from "axios";

// export const EXERCISE_URL = `${process.env.REACT_APP_LOCALHOST_API}/exercise`;
export const EXERCISE_URL = `${process.env.REACT_APP_PLATFORM_API}/exercise`;
const ESDA_API = process.env.REACT_APP_ESDA_API ? process.env.REACT_APP_ESDA_API : ''
export const EXERCISE_URL_ESDA= `${ESDA_API}/api/v1/exercises`;

export function getExerc(id) {
    const processed_url = `${ESDA_API}/api/v1/exerc/${id}`
    console.log("getExerc", {processed_url})
    return fetch(processed_url)
    // console.log(`${EXERCISE_URL}/1`)
    // return axios.get(`${EXERCISE_URL}/${id}`);
}
export function getExerciseTestApi(id) {
    const processed_url = `${EXERCISE_URL_ESDA}/${id}`
    console.log("getExercise_TestApi", {processed_url})
    return fetch(processed_url)
    // console.log(`${EXERCISE_URL}/1`)
    // return axios.get(`${EXERCISE_URL}/${id}`);
}
export function getExercise(id) {
    console.log("getExercise_byId", {processed_url:`${EXERCISE_URL}/${id}`})
    return fetch(`${EXERCISE_URL}/${id}`)
    // console.log(`${EXERCISE_URL}/1`)
    // return axios.get(`${EXERCISE_URL}/${id}`);
}
export function getExerciseItemsIds(template_id,level) {
    const processed_url = `${ESDA_API}/api/v1/getids/${template_id}${level ? '?level='+level :''}`
    return fetch(processed_url)
    // return axios.get(`${EXERCISE_URL}/${id}`);
}
export function getL2ExerciseAvatars(id) {
    return fetch(`${EXERCISE_URL}/language2/avatars/${id}`)
}
export function getL1Exercise(urlWithId) {  //urlWithId  -->     't1/1'
    console.log(`${EXERCISE_URL}/language/${urlWithId}`)
    return fetch(`${EXERCISE_URL}/language/${urlWithId}`)
    // return axios.get(`${EXERCISE_URL}/language/${urlWithId}`);
}
export function getSpeechExercise(id) {
    return fetch(`${EXERCISE_URL}/speech/${id}`)
    // return axios.get(`${EXERCISE_URL}/speech/${id}`);
}
export function sendAnswer(id,data) {
    return axios.post(`${EXERCISE_URL}/${id}`,data);
}