/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import {findPlans} from "../../api/plansCrud";
import WidgetTableHeader from "./WidgetsUI/WidgetTableHeader";
import ProgressBar from "./WidgetsUI/ProgressBar";

export function WidgetPublicPlans({className}) {
    const [publicPlansList, setPublicPlansList] = useState([])

    useEffect(() => {
        findPlans({filter: {patient_id: null, plan_type: 0}})
            // .getAllPlans(queryParams)
            .then(async response => {
                console.log({response})
                const data = await response.json()
                console.log({data})
                if (data && data.data)
                    setPublicPlansList(data.data)
            })
            .catch(error => {
                console.log({error})
            });
    }, [])

    console.log({publicPlansList})
    return (
        <div className={`card card-custom ${className}`}>
            {/* begin::Header */}
            <WidgetTableHeader
                title={"Γενικά Πλάνα"}
                // subtitle={""}
                buttonTitle={"Λίστα Πλάνων"}
                buttonLink={"/public-plans"}
            />

            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body py-0 pb-2">
                {/* begin::Table */}
                <div className="table-responsive" style={{maxHeight: "420px", overflowY: "auto"}}>
                    <table
                        className="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_1"
                    >
                        <thead>
                        <tr className="text-left">
                            <th style={{minWidth: "20px"}}/>
                            <th className="px-1" style={{width: "200px", minWidth: "120px"}}> ΤΙΤΛΟΣ</th>
                            <th className="px-1" style={{width: "200px", minWidth: "125px"}}> ΠΕΡΙΓΡΑΦΗ</th>
                            <th className="px-1" style={{width: "120px", minWidth: "80px"}}> ΑΣΘΕΝΕΙΣ</th>
                            <th className="pr-0 text-right" style={{minWidth: "50px"}}/>
                        </tr>
                        </thead>
                        <tbody>

                        {/* Test ROW */}
                        {!(publicPlansList && publicPlansList.length > 0) &&
                        <>

                        </>
                        }
                        {publicPlansList && publicPlansList.length > 0 &&
                        publicPlansList.map(plan => {
                            return <tr key={plan.id}>
                                <td className="px-2">
                                    <div className="symbol symbol-50 symbol-light p-1">
                                    <span className="symbol-label">
                                      <SVG
                                          src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
                                          className="h-75 align-self-center"
                                      />
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <span
                                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                        {plan.title}</span>
                                    {/*<span className="text-muted font-weight-bold text-muted d-block">*/}
                                    {/* {plan.description}</span>*/}
                                </td>
                                <td>
                                        <span className="text-muted font-weight-bold text-muted d-block">
                                           {plan.description}</span>
                                    </td>
                                <td>
                                    {/*<ProgressBar percentage={"33"} />*/}
                                    <i className="fas fa-users fa-sm"/> {" "}  {plan.dataPatients.length}
                                </td>
                                {/* Progress-Bar */}
                                {/*<td>*/}
                                {/*    /!*<ProgressBar percentage={"33"} />*!/*/}
                                {/*</td>*/}
                                {/* ACTION BUTTONS */}
                                <td className="pr-2 text-right">
                                    <Link className={'btn btn-icon btn-light btn-hover-info btn-sm'}
                                          to={"/public-plans/" + plan.id + "/link-patients"}>
                                       <span className="svg-icon svg-icon-md svg-icon-primary ">
                                        {/*<i className="fa fa-arrow-right text-primary"/>*/}
                                           <i className="fa fa-link"/>
                                    </span>
                                    </Link>
                                    {/*SETTINGS*/}
                                    {/*<a href="#"*/}
                                    {/*    className="btn btn-icon btn-light btn-hover-primary btn-sm">*/}
                                    {/*    <span className="svg-icon svg-icon-md svg-icon-primary">*/}
                                    {/*      <SVG*/}
                                    {/*          src={toAbsoluteUrl(*/}
                                    {/*              "/media/svg/icons/General/Settings-1.svg"*/}
                                    {/*          )}*/}
                                    {/*      />*/}
                                    {/*    </span>*/}
                                    {/*</a>*/}
                                    {/*EDIT*/}
                                    {/*<a href="#"*/}
                                    {/*    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">*/}
                                    {/*        <span className="svg-icon svg-icon-md svg-icon-primary">*/}
                                    {/*          <SVG*/}
                                    {/*              src={toAbsoluteUrl(*/}
                                    {/*                  "/media/svg/icons/Communication/Write.svg"*/}
                                    {/*              )}*/}
                                    {/*          />*/}
                                    {/*        </span>*/}
                                    {/*</a>*/}
                                    {/*DELETE*/}
                                    {/*<a href="#"*/}
                                    {/*   className="btn btn-icon btn-light btn-hover-primary btn-sm">*/}
                                    {/*    <span className="svg-icon svg-icon-md svg-icon-primary">*/}
                                    {/*        <SVG*/}
                                    {/*            src={toAbsoluteUrl(*/}
                                    {/*                "/media/svg/icons/General/Trash.svg"*/}
                                    {/*            )}*/}
                                    {/*        />*/}
                                    {/*    </span>*/}
                                    {/*</a>*/}
                                </td>
                            </tr>
                        })
                        }
                        </tbody>
                    </table>
                </div>
                {/* end::Table */}
            </div>
            {/* end::Body */}
        </div>
    );
}
