import React from "react";
import {useSubheader} from "../../_metronic/layout";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import ProfileEdit from "../components/Profile/ProfileEdit";

export const ProfilePage = () => {
    const subheader = useSubheader();
    subheader.setTitle("Προφίλ Χρήστη");

    const match = useRouteMatch();

    return (<>
        <Route path={match.path}>
            {/*PROFILE VIEW CARD TO BE CREATED HERE*/}
            <ProfileEdit/>
        </Route>
        <Switch>
            {/*</Route>*/}
            <Redirect to="/profile"/>
        </Switch>


    </>);
};