import React from 'react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import classes from "./TabTitleSVG.module.css";

// const Input = React.forwardRef((props, ref) => {
// <input ref={ref}  {...props.input} />

const TabTitleSVG = props => {
    const titleClass = props.isActive ? classes.activeTitle : "card-label";
    const tabClass = props.isActive ? `${classes.activeTab} ${classes.tab}` : classes.tab;
    return (
            <div className={tabClass} onClick={props.onClick}>
                <span className="svg-icon mr-1">
                    <SVG src={toAbsoluteUrl(props.absoluteUrl)}/>
                </span>
                <span className={titleClass}>
                    {props.title}
                </span>
            </div>
    );
};

export default TabTitleSVG;
