import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import UserForm from "../Patients/patient-edit/UserForm";
import {useHistory} from "react-router-dom";
import * as actions from "../../../redux/patients/patientsActions";

const ProfileEdit = () => {
//newEntryType === 'patient' || 'therapist' || undefined(false)

    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => (state.auth.user), shallowEqual);

    const {actionsLoading, patientForEdit:userForEdit, error} = useSelector(
        (state) => ({
            actionsLoading: state.patients.actionsLoading,
            patientForEdit: state.patients.patientForEdit,
            error: state.patients.error
        }),
        shallowEqual
    );
    useEffect(() => {
        if(user.id) {
            dispatch(actions.fetchPatient(user.id));
            // setIsLoading(true)
            console.log('editUser')
        }
    }, [user.id, dispatch]);

    const saveUser = (patient) => {
        if (!user.id) {
            // server request for creating patient
            // dispatch(actions.createPatient(patient)).then(() => console.log());
        } else {
            // server request for updating patient
            dispatch(actions.updatePatient(user.id,patient)).then(() => history.goBack());
        }
        console.log({patient})
    };


    // server request for saving patient

    return (<>
            {/*{error && <Redirect to="/error/error-v3"/>}*/}

            {userForEdit && <UserForm patient={userForEdit}
                                      id={user.id}
                                      savePatient={saveUser}
                                      actionsLoading={actionsLoading}
                                      error={error}
            />}
            {/*{isLoading &&*/}
            {/*<div className='text-center'><span className="spinner spinner-primary spinner-lg m-10"/></div>}*/}

        </>
    );
};

export default ProfileEdit;