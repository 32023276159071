import React from "react";
import { Redirect, Route } from "react-router-dom";
import isAuthorized from "./AuthorizedFunction";

export function PrivateRoute({ component: Component, roles, ...rest }) {

  return (
    <Route {...rest} >

      {isAuthorized(roles) ? (
          <Component />
       ) : (
          <Redirect to={{pathname: "/"}}/>
      )}
    </Route>
  );
}
