import axios from "axios";

export const PLANS_URL = "api/plans";
// export const PLANS_URL_ESDA= `${process.env.REACT_APP_ESDA_API}/api/v1/plans`;
const ESDA_API = process.env.REACT_APP_ESDA_API ? process.env.REACT_APP_ESDA_API : ''
export const PLANS_URL_TEST= `${ESDA_API}/api/v1/plans`;

// CREATE =>  POST: add a new customer to the server
export function createPlan(plan) {
  console.log("CreatePlan: ", plan)
  // return axios.post(PLANS_URL, { plan });

  return fetch(`${PLANS_URL_TEST}`,{
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(plan),
  }).catch(err=>{
    console.log("ERROR:", err)})
}

// READ
export function getAllPlans() {
  // return axios.get(PLANS_URL);
  return fetch(`${PLANS_URL_TEST}`)
}

export function getPlanById(planId) {
  // return axios.get(`${PLANS_URL}/${planId}`);
  return fetch(`${PLANS_URL_TEST}/${planId}`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPlans(queryParams) {
  return fetch(`${PLANS_URL_TEST}/find`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // mode: 'no-cors',
        body: JSON.stringify(queryParams),
  }).catch(err=>{
    console.log("ERROR:", err)})
// return axios.post(`${PLANS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updatePlan(plan) {
  // return axios.put(`${PLANS_URL}/${plan.id}`, {plan });
  return fetch(`${PLANS_URL_TEST}/${plan.id}?${plan.title?`title=${plan.title}&`:''}${plan.daily_session?`daily_session=${plan.daily_session}&`:''}`,{
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(plan),
  }).catch(err=>{
    console.log("ERROR:", err)})
}

// UPDATE Status
export function updateStatusForPlans(ids, status) {
  return axios.post(`${PLANS_URL}/updateStatusForPlans`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deletePlan(planId) {
  // return axios.delete(`${PLANS_URL}/${planId}`);
  return fetch(`${PLANS_URL_TEST}/${planId}`,{
    method: 'DELETE',
    // headers: { 'Content-Type': 'application/json' },
  }).catch(err=>{
    console.log("ERROR:", err)})

}


// DELETE Customers by ids
export function deletePlans(ids) {
  return axios.post(`${PLANS_URL}/deletePlans`, { ids });
}
