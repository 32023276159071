/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import isAuthorized from "../../../../../app/helpers/AuthorizedFunction";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Αρχική</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*/!*begin::1 Level*!/*/}
            {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/profile')}`}>*/}
            {/*    <NavLink className="menu-link" to="/profile">*/}
            {/*        <span className="menu-text">Λογαριασμός</span>*/}
            {/*        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*/!*end::1 Level*!/*/}


            {/*begin::1 Level*/}
            {isAuthorized(['therapist', 'admin']) && <>
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/patients')}`}>
                    <NavLink className="menu-link" to="/patients">
                        <span className="menu-text">Ασθενείς</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/public-plans')}`}>
                    <NavLink className="menu-link" to="/public-plans">
                        <span className="menu-text">Γενικά Πλάνα</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
            </>
            }
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {/*isAuthorized(['patient'])*/}
            {!isAuthorized(['therapist','admin']) && <>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/exercise/plans')}`}>
                <NavLink className="menu-link" to="/exercise/plans">
                    <span className="menu-text">Πλάνα</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/calendar')}`}>*/}
            {/*    <NavLink className="menu-link" to="/calendar">*/}
            {/*        <span className="menu-text">Ημερολόγιο</span>*/}
            {/*        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            </>
            }
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {isAuthorized(['therapist', 'admin']) &&
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/results')}`}>
                <NavLink className="menu-link" to="/results">
                    <span className="menu-text">Αποτελέσματα</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </NavLink>
            </li>
            }
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {isAuthorized(['therapist', 'admin']) &&
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/evaluation')}`}>
                <Link className="menu-link"
                      to={{pathname: "https://apps.ilsp.gr/planv/dashboard/"}}
                      target="_blank" rel={"noopener noreferrer"}
                >
                    <span className="menu-text">Αξιολόγηση ασθενών</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </Link>
            </li>
            }
            {/*end::1 Level*/}
            {!isAuthorized(['therapist', 'admin']) &&
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/evaluation')}`}>
                <Link className="menu-link"
                      to={{pathname: "https://apps.ilsp.gr/planv/data-collection/"}}
                      target="_blank" rel={"noopener noreferrer"}
                >
                    <span className="menu-text">Αξιολόγηση </span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </Link>
            </li>
            }
            {/*begin::1 Level*/}
            {isAuthorized(['therapist', 'admin']) &&
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/exercise-samples')}`}>
                <NavLink className="menu-link" to="/exercise-samples">
                    <span className="menu-text">Δείγματα Ασκήσεων</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
                </NavLink>
            </li>
            }
            {/*end::1 Level*/}

            {/*/!*Classic submenu*!/*/}
            {/*/!*begin::1 Level*!/*/}
            {/*<li*/}
            {/*    data-menu-toggle={layoutProps.menuDesktopToggle}*/}
            {/*    aria-haspopup="true"*/}
            {/*    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>*/}
            {/*    <NavLink className="menu-link menu-toggle" to="/google-material">*/}
            {/*        <span className="menu-text">Material UI</span>*/}
            {/*        <i className="menu-arrow"></i>*/}
            {/*    </NavLink>*/}
            {/*    <div className="menu-submenu menu-submenu-classic menu-submenu-left">*/}
            {/*        <ul className="menu-subnav">*/}
            {/*            /!*begin::2 Level*!/*/}
            {/*            <li*/}
            {/*                className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/inputs')}`}*/}
            {/*                data-menu-toggle="hover"*/}
            {/*                aria-haspopup="true"*/}
            {/*            >*/}
            {/*                <NavLink className="menu-link menu-toggle" to="/google-material/inputs">*/}
            {/*                    <span className="svg-icon menu-icon">*/}
            {/*                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/PenAndRuller.svg")} />*/}
            {/*                    </span>*/}
            {/*                    <span className="menu-text">*/}
            {/*                        Inputs*/}
            {/*                    </span>*/}
            {/*                    <i className="menu-arrow" />*/}
            {/*                </NavLink>*/}
            {/*                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>*/}
            {/*                    <ul className="menu-subnav">*/}
            {/*                        /!*begin::3 Level*!/*/}
            {/*                        <li className={`menu-item ${getMenuItemActive('/google-material/inputs/autocomplete')}`}>*/}
            {/*                            <NavLink className="menu-link" to="/google-material/inputs/autocomplete">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot"><span /></i>*/}
            {/*                                <span className="menu-text">Autocomplete</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                        /!*end::3 Level*!/*/}

            {/*                        /!*begin::3 Level*!/*/}
            {/*                        <li className={`menu-item ${getMenuItemActive('/google-material/inputs/buttons')}`}>*/}
            {/*                            <NavLink className="menu-link" to="/google-material/inputs/buttons">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot"><span /></i>*/}
            {/*                                <span className="menu-text">Buttons</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                        /!*end::3 Level*!/*/}

            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </li>*/}
            {/*            /!*end::2 Level*!/*/}


            {/*            /!*begin::2 Level*!/*/}
            {/*            <li*/}
            {/*                className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/surfaces')}`}*/}
            {/*                data-menu-toggle="hover"*/}
            {/*                aria-haspopup="true"*/}
            {/*            >*/}
            {/*                <NavLink className="menu-link menu-toggle" to="/google-material/surfaces">*/}
            {/*                    <span className="svg-icon menu-icon">*/}
            {/*                        <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-left-panel-1.svg")} />*/}
            {/*                    </span>*/}
            {/*                    <span className="menu-text">*/}
            {/*                        Surfaces*/}
            {/*                    </span>*/}
            {/*                    <i className="menu-arrow" />*/}
            {/*                </NavLink>*/}
            {/*                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>*/}
            {/*                    <ul className="menu-subnav">*/}
            {/*                        /!*begin::3 Level*!/*/}
            {/*                        <li className={`menu-item ${getMenuItemActive('/google-material/surfaces/app-bar')}`}>*/}
            {/*                            <NavLink className="menu-link" to="/google-material/surfaces/app-bar">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot"><span /></i>*/}
            {/*                                <span className="menu-text">App Bar</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                        /!*end::3 Level*!/*/}

            {/*                        /!*begin::3 Level*!/*/}
            {/*                        <li className={`menu-item ${getMenuItemActive('/google-material/surfaces/paper')}`}>*/}
            {/*                            <NavLink className="menu-link" to="/google-material/surfaces/paper">*/}
            {/*                                <i className="menu-bullet menu-bullet-dot"><span /></i>*/}
            {/*                                <span className="menu-text">Paper</span>*/}
            {/*                            </NavLink>*/}
            {/*                        </li>*/}
            {/*                        /!*end::3 Level*!/*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </li>*/}
            {/*            /!*end::2 Level*!/*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</li>*/}
            {/*/!*end::1 Level*!/*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
