import * as requestFromServer from "../../app/api/patientsCrud";
import {callTypes, patientsSlice} from "./patientsSlice";
import {countUsers, createEsdaUser, getEsdaUserByKeycloakId, getUserById} from "../../app/api/patientsCrud";

const {actions} = patientsSlice;

export const fetchPatients = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUsersEsda(queryParams)
      .then(async response => {
        // Keycloak Find Users
        // const totalCount = await countUsers(queryParams)
        //     .then(response => response.data)
        //     .catch(error => {console.log("countUsers error: ",error)});
        const data = await response.data
        console.log({response})
      const entities = response.data.data
        const totalCount = response.data.length
        // const totalCount = response.data.length
      dispatch(actions.patientsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find patients";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchPatient = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    if (!id) {
        return dispatch(actions.patientFetched({ patientForEdit: undefined }));
    }
    return requestFromServer
        .getEsdaUserByKeycloakId(id)
        .then(response => {
            const patient = response.data;
            dispatch(actions.patientFetched({ patientForEdit: patient }));
        })
        .catch(error => {
            error.clientMessage = "Can't find patient";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};
export const fetchKeycloakUser = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  if (!id) {
    return dispatch(actions.patientFetched({ patientForEdit: undefined }));
  }
  return requestFromServer
    .getUserById(id)
    .then(response => {
      const patient = response.data;
      dispatch(actions.patientFetched({ patientForEdit: patient }));
    })
    .catch(error => {
      error.clientMessage = "Can't find patient";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePatient = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteKeycloakUser(id)
    .then(response => {
      dispatch(actions.patientDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete patient";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUser = user => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createKeycloakUser(user)
    .then(response => {
      const userId = response && response.headers && response.headers.location && response.headers.location.split("/").pop() // URL get user by ID
      // console.log({response, patientForCreation})
      createEsdaUser(userId, user.username, user.created_by, user.type).then(res => {
        // Todo  replace below with data parsed from POST "create esda user"
        getUserById(userId).then(res => {
          if (res && res.data) {
            dispatch(actions.patientCreated({patient: res.data}));
          }
        })
      } )

      // const { patient } = response.data;
    })
    .catch(error => {
      console.log({error})
      error.clientMessage = error && error.response && error.response.data && error.response.data.errorMessage ? error.response.data.errorMessage :"Can't create patient";
      dispatch(actions.catchError({ error:error.clientMessage, callType: callTypes.action }));
    });
};

export const updatePatient = (id, patientData) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
      // .updateUser(patient)
    .updatePatient(id, patientData)
    .then(() => {
      dispatch(actions.patientUpdated({id, patientData }));
    })
    .catch(error => {
      error.clientMessage = "Can't update patient";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePatientsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForPatients(ids, status)
    .then(() => {
      dispatch(actions.patientsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update patients status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePatients = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePatients(ids)
    .then(() => {
      dispatch(actions.patientsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete patients";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};