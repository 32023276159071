import React, {useEffect, useState} from 'react';
import {TilesWidget11} from "../TilesWidget11";
import {shallowEqual, useSelector} from "react-redux";
import {findUsersEsda} from "../../../api/patientsCrud";
import {findPlans} from "../../../api/plansCrud";

const TilesInfo = () => {

    const [totalPatients, setTotalPatients] = useState([])
    const [totalPublicPlans, setTotalPublicPlans] = useState([])
    const [totalPersonalPlans, setTotalPersonalPlans] = useState([])

    const {id: userId} = useSelector((state) => (state.auth.user), shallowEqual);
    console.log({userId})

    useEffect(() => {
        findUsersEsda({user_id: userId, filter: {type: 0}})
            .then(async response => {
                // console.log({response})
                // const data = await response.json()
                const usersCount = response.data.length
                setTotalPatients(usersCount)
            })
            .catch(error => {
                console.log({error})
            });
        findPlans({filter: {patient_id: null, plan_type: 0}})
            // .getAllPlans(queryParams)
            .then(async response => {
                // console.log({response})
                const data = await response.json()
                if (data && data.totalCount)
                    setTotalPublicPlans(data.totalCount)
            })
            .catch(error => {
                console.log({error})
            });
        findPlans({filter: {therapist_id: userId, patient_id: null, plan_type: 1}})
            // .getAllPlans(queryParams)
            .then(async response => {
                // console.log({response})
                const data = await response.json()
                // console.log({data})
                if (data && (data.totalCount || data.totalCount === 0))
                    setTotalPersonalPlans(data.totalCount)
            })
            .catch(error => {
                console.log({error})
            });
    }, [])

    return (
        <>
            <div className="col-sm-6">
                <div className="row">
                    <div className="col">
                        <TilesWidget11 className="gutter-b"
                                       baseColor="primary"
                                       widgetHeight="150px"
                                       title="Συνολο Ασθενών"
                                       content={totalPatients}
                        />
                    </div>
                    <div className="col">
                        <TilesWidget11 className="gutter-b"
                                       baseColor="success"
                                       widgetHeight="150px"
                                       title="Συνολο Πλάνων"
                                       content={totalPublicPlans + totalPersonalPlans}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="row">
                    <div className="col">
                        <TilesWidget11 className="gutter-b"
                                       baseColor="info"
                                       widgetHeight="150px"
                                       title="Ατομικά Πλάνα"
                                       content={totalPersonalPlans}
                        />
                    </div>
                    <div className="col">
                        <TilesWidget11 className="gutter-b"
                                       baseColor="primary"
                                       widgetHeight="150px"
                                       title="Γενικά Πλάνα"
                                       content={totalPublicPlans}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TilesInfo;
