/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink,useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import isAuthorized from "../../../../../app/helpers/AuthorizedFunction";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  console.log("AsideMenuList",{location})
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {isAuthorized(["therapist"]) &&
            <>
        {/*begin::1 Level*/}
          <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
          >
          <NavLink className="menu-link" to="/builder">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
          </span>
          <span className="menu-text">Layout Builder</span>
          </NavLink>
          </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
          <li
          className={`menu-item ${getMenuItemActive("/my-page", false)}`}
          aria-haspopup="true"
          >
          <NavLink className="menu-link" to="/my-page">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span>
          <span className="menu-text">My Page</span>
          </NavLink>
          </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
          <li
          className={`menu-item ${getMenuItemActive("/profile", false)}`}
          aria-haspopup="true"
          >
          <NavLink className="menu-link" to="/profile">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span>
          <span className="menu-text">Λογαριασμός</span>
          </NavLink>
          </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
          <li
          className={`menu-item ${getMenuItemActive("/patients", false)}`}
          aria-haspopup="true"
          >
          <NavLink className="menu-link" to="/patients">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span>
          <span className="menu-text">Ασθενείς</span>
          </NavLink>
          </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
          <li
          className={`menu-item ${getMenuItemActive("/plan", true)}`}
          aria-haspopup="true"
          >
          <NavLink className="menu-link menu-toggle" to="/plan">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span>
          <span className="menu-text">Πλάνο</span>
          <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
          <li className="menu-item  menu-item-parent" aria-haspopup="true">
          <span className="menu-link">
          <span className="menu-text">Πλάνο</span>
          </span>
          </li>

        {/* Feedback */}
        {/*begin::2 Level*/}
          <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/plans/new",
          true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          >
          <NavLink
          className="menu-link "
          to="/plans/new"
          >
          <i className="menu-bullet menu-bullet-dot">
          <span />
          </i>
          <span className="menu-text">New Plan</span>
          </NavLink>
          </li>
        {/*end::2 Level*/}

          </ul>
          </div>
          </li>
        {/*end::1 Level*/}
            </>
        }
        {/* ---- Comments 140 - 388  ------*/}

        {/*/!* Components *!/*/}
        {/*/!* begin::section *!/*/}
        {/*<li className="menu-section ">*/}
        {/*  <h4 className="menu-text">Components</h4>*/}
        {/*  <i className="menu-icon flaticon-more-v2"></i>*/}
        {/*</li>*/}
        {/*/!* end:: section *!/*/}

        {/*/!* Material-UI *!/*/}
        {/*/!*begin::1 Level*!/*/}
        {/*<li*/}
        {/*  className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
        {/*    "/google-material",*/}
        {/*    true*/}
        {/*  )}`}*/}
        {/*  aria-haspopup="true"*/}
        {/*  data-menu-toggle="hover"*/}
        {/*>*/}
        {/*  <NavLink className="menu-link menu-toggle" to="/google-material">*/}
        {/*    <span className="svg-icon menu-icon">*/}
        {/*      <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />*/}
        {/*    </span>*/}
        {/*    <span className="menu-text">Material UI</span>*/}
        {/*    <i className="menu-arrow" />*/}
        {/*  </NavLink>*/}
        {/*  <div className="menu-submenu ">*/}
        {/*    <i className="menu-arrow" />*/}
        {/*    <ul className="menu-subnav">*/}
        {/*      <li className="menu-item  menu-item-parent" aria-haspopup="true">*/}
        {/*        <span className="menu-link">*/}
        {/*          <span className="menu-text">Material UI</span>*/}
        {/*        </span>*/}
        {/*      </li>*/}

        {/*      /!* Feedback *!/*/}
        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
        {/*          "/google-material/feedback",*/}
        {/*          true*/}
        {/*        )}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*        data-menu-toggle="hover"*/}
        {/*      >*/}
        {/*        <NavLink*/}
        {/*          className="menu-link menu-toggle"*/}
        {/*          to="/google-material/feedback"*/}
        {/*        >*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Feedback</span>*/}
        {/*          <i className="menu-arrow" />*/}
        {/*        </NavLink>*/}
        {/*        <div className="menu-submenu">*/}
        {/*          <i className="menu-arrow" />*/}
        {/*          <ul className="menu-subnav">*/}
        {/*            /!*begin::3 Level*!/*/}
        {/*            <li*/}
        {/*              className={`menu-item ${getMenuItemActive(*/}
        {/*                "/google-material/feedback/progress"*/}
        {/*              )}`}*/}
        {/*              aria-haspopup="true"*/}
        {/*            >*/}
        {/*              <NavLink*/}
        {/*                className="menu-link"*/}
        {/*                to="/google-material/feedback/progress"*/}
        {/*              >*/}
        {/*                <i className="menu-bullet menu-bullet-dot">*/}
        {/*                  <span />*/}
        {/*                </i>*/}
        {/*                <span className="menu-text">Progress</span>*/}
        {/*              </NavLink>*/}
        {/*            </li>*/}
        {/*            /!*end::3 Level*!/*/}

        {/*            /!*begin::3 Level*!/*/}
        {/*            <li*/}
        {/*              className={`menu-item ${getMenuItemActive(*/}
        {/*                "/google-material/feedback/dialogs"*/}
        {/*              )}`}*/}
        {/*              aria-haspopup="true"*/}
        {/*            >*/}
        {/*              <NavLink*/}
        {/*                className="menu-link"*/}
        {/*                to="/google-material/feedback/dialogs"*/}
        {/*              >*/}
        {/*                <i className="menu-bullet menu-bullet-dot">*/}
        {/*                  <span />*/}
        {/*                </i>*/}
        {/*                <span className="menu-text">Dialogs</span>*/}
        {/*              </NavLink>*/}
        {/*            </li>*/}
        {/*            /!*end::3 Level*!/*/}

        {/*            /!*begin::3 Level*!/*/}
        {/*            <li*/}
        {/*              className={`menu-item ${getMenuItemActive(*/}
        {/*                "/google-material/feedback/snackbars"*/}
        {/*              )}`}*/}
        {/*              aria-haspopup="true"*/}
        {/*            >*/}
        {/*              <NavLink*/}
        {/*                className="menu-link"*/}
        {/*                to="/google-material/feedback/snackbars"*/}
        {/*              >*/}
        {/*                <i className="menu-bullet menu-bullet-dot">*/}
        {/*                  <span />*/}
        {/*                </i>*/}
        {/*                <span className="menu-text">Snackbars</span>*/}
        {/*              </NavLink>*/}
        {/*            </li>*/}
        {/*            /!*end::3 Level*!/*/}
        {/*          </ul>*/}
        {/*        </div>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</li>*/}
        {/*/!*end::1 Level*!/*/}


        {/*/!* Custom *!/*/}
        {/*/!* begin::section *!/*/}
        {/*<li className="menu-section ">*/}
        {/*  <h4 className="menu-text">Custom</h4>*/}
        {/*  <i className="menu-icon flaticon-more-v2"></i>*/}
        {/*</li>*/}
        {/*/!* end:: section *!/*/}

        {/*/!* Error Pages *!/*/}
        {/*/!*begin::1 Level*!/*/}
        {/*<li*/}
        {/*  className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
        {/*    "/error",*/}
        {/*    true*/}
        {/*  )}`}*/}
        {/*  aria-haspopup="true"*/}
        {/*  data-menu-toggle="hover"*/}
        {/*>*/}
        {/*  <NavLink className="menu-link menu-toggle" to="/error">*/}
        {/*    <span className="svg-icon menu-icon">*/}
        {/*      <SVG*/}
        {/*        src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}*/}
        {/*      />*/}
        {/*    </span>*/}
        {/*    <span className="menu-text">Error Pages</span>*/}
        {/*    <i className="menu-arrow" />*/}
        {/*  </NavLink>*/}
        {/*  <div className="menu-submenu ">*/}
        {/*    <i className="menu-arrow" />*/}
        {/*    <ul className="menu-subnav">*/}
        {/*      <li className="menu-item  menu-item-parent" aria-haspopup="true">*/}
        {/*        <span className="menu-link">*/}
        {/*          <span className="menu-text">Error Pages</span>*/}
        {/*        </span>*/}
        {/*      </li>*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v1")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v1">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page - 1</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v2")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v2">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page -2</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v3")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v3">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page - 3</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v4")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v4">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page - 4</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v5")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v5">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page - 5</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}

        {/*      /!*begin::2 Level*!/*/}
        {/*      <li*/}
        {/*        className={`menu-item ${getMenuItemActive("/error/error-v6")}`}*/}
        {/*        aria-haspopup="true"*/}
        {/*      >*/}
        {/*        <NavLink className="menu-link" to="/error/error-v6">*/}
        {/*          <i className="menu-bullet menu-bullet-dot">*/}
        {/*            <span />*/}
        {/*          </i>*/}
        {/*          <span className="menu-text">Error Page - 6</span>*/}
        {/*        </NavLink>*/}
        {/*      </li>*/}
        {/*      /!*end::2 Level*!/*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</li>*/}
        {/*/!*end::1 Level*!/*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
