/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {Link} from "react-router-dom";
import {findUsersEsda} from "../../api/patientsCrud";
import WidgetTableHeader from "./WidgetsUI/WidgetTableHeader";
import {shallowEqual, useSelector} from "react-redux";

export function WidgetPatients({className}) {
    const [patientsList, setPatientsList] = useState([])

    const {id: userId} = useSelector((state) => (state.auth.user), shallowEqual);

    useEffect(() => {
        findUsersEsda({user_id: userId, filter: {type: 0}})
            .then(async response => {
                console.log({response})
                // const data = await response.json()
                const entities = response.data.data
                console.log({entities})
                setPatientsList(entities)
            })
            .catch(error => {
                console.log({error})
            });
    }, [])
    return (
        <div className={`card card-custom ${className}`}>
            {/* begin::Header */}
            <WidgetTableHeader
                title={"Ασθενείς"}
                // subtitle={""}
                buttonTitle={"Λίστα Χρηστών"}
                buttonLink={"/patients"}
            />
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body py-0 pb-2">
                {/* begin::Table */}
                <div className="table-responsive" style={{maxHeight: "420px", overflowY: "auto"}}>
                    <table
                        className="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_1"
                    >

                        {!(patientsList && patientsList.length > 0) &&
                        <tbody>
                        <tr>
                            <td>
                                Δεν υπάρχουν ασθενείς
                            </td>
                        </tr>
                        </tbody>
                        }

                        {patientsList && patientsList.length > 0 &&
                        <>
                            <thead>
                            <tr className="text-left">
                                <th style={{minWidth: "20px"}}/>
                                <th className="pr-0"
                                    style={{width: "200px", minWidth: "125px"}}> Username
                                </th>
                                <th style={{width: "150px", minWidth: "100px"}}> ΟΝΟΜΑTΕπωνυμο</th>
                                {/*<th style={{width: "150px", minWidth: "100px"}}> ΟΝΟΜΑ</th>*/}
                                <th className="pr-0 text-right" style={{minWidth: "80px"}}>

                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {patientsList.map(p => {
                                return <tr key={p.id}>
                                    <td className="pr-0">
                                        <div className="symbol symbol-50 symbol-light mt-1">
                                    <span className="symbol-label">
                                      <SVG
                                          src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                          className="h-75 align-self-end"
                                      />
                                    </span>
                                        </div>
                                    </td>
                                    <td className="pl-0">
                                <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                    {p.username}
                                </span>
                                    </td>
                                    <td>
                                        <div className="text-muted font-weight-bold text-muted d-block">
                                            {p.firstName}
                                      </div>
                                        <div className="text-muted font-weight-bold text-muted d-block">
                                            {p.lastName}
                                        </div>
                                    </td>
                                    {/* ACTION BUTTONS */}
                                    <td className="pr-0 text-right">
                                        <Link className={'btn btn-icon btn-light btn-hover-dark btn-sm'}
                                              to={"/patients/" + p.keycloak_id+ "/plans"}>
                                           <span className="svg-icon svg-icon-md svg-icon-dark ">
                                                   <i className="fas fa-clipboard-list fa-2x"/>
                                           </span>
                                        </Link>
                                        <Link className={'btn btn-icon btn-light btn-hover-primary btn-sm'}
                                              to={"/patients/" + p.keycloak_id + "/edit"}>
                                           <span className="svg-icon svg-icon-md svg-icon-primary ">
                                               <SVG
                                                   src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                               />
                                           </span>
                                        </Link>
                                    </td>
                                </tr>
                            })
                            }
                            </tbody>
                        </>
                        }
                    </table>
                </div>
                {/* end::Table */}
            </div>
            {/* end::Body */}
        </div>
    );
}
