import React from "react";
import {Link} from "react-router-dom";
import classes from "./MyScrollableList.module.css";
import {ListGroup, ListGroupItem, ProgressBar} from "react-bootstrap";
import isAuthorized from "../../../../helpers/AuthorizedFunction";

const MyScrollableList = (props) => {
    const isUserTester = isAuthorized(['patient_tester'])
    console.log("TEST: ", isUserTester)
    return <div className={classes.card} style={{height:props.height}}>
        <h3>
            {/*<button onClick={props.onExpandSectionHandler}>s</button>*/}
            <u> {props.title}</u></h3>
        <div className={classes.listArea}>
            {props.entities && !(props.entities.length > 0) && <div>{props.emptyMessage}</div>}

            {props.entities && props.entities.map(item => {
                // console.log({item})
                const isAvailable = (item.isAvailable || isUserTester) && !item.isCompleted;
                const isCompleted = item.isCompleted;
                return <ListGroupItem key={item.id}
                                       className={`d-flex flex-column  justify-content-between ${classes.item}`}
                                       style={{
                                           borderLeft: "3px solid lightblue",
                                           textAlign: "left",
                                           margin: "0.6rem 0",
                                           padding: "0.4rem",
                                           // backgroundColor: (item.id === +props.activeItemId) ? "lightcyan" : "#f5f5fd"
                                       }}
                                       variant={isCompleted ? "secondary" : (item.isAvailable ? undefined : "dark")}
                >
                    <div  className={`d-flex flex-row  justify-content-between`}
                    >
                        <h4 style={{padding: "2px"}}> {item.template_category && item.template_category.label} </h4>
                        <span className={'d-flex align-items-center '}>
                        <span className={'font-size-xs p-1'}>#{item.id}</span>

                            {isAvailable &&
                            <Link className={'btn btn-danger'}
                                  style={{padding: "0.3rem 0.4rem"}}
                                  to={`${props.itemPath}/${item.id}`}
                            >
                                <i className={"fa fa-angle-double-right"}/>
                            </Link>
                            }
                        </span>
                    </div>
                    {isAvailable && <div className={`d-flex flex-row  justify-content-center align-items-center`}>
                        <div className={'px-1'}>{0}</div>
                        <div style={{width: '60%'}}>
                            <ProgressBar variant="info" now={item.items_completed} label={item.items_completed} min={0} max={item.total_items}/>
                        </div>
                        <div className={'pl-2'}>{item.total_items}</div>
                    </div>
                    }
                </ListGroupItem>
            })}
        </div>
    </div>;
}
export default MyScrollableList;
