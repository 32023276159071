import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import Input from "../../UI/Form/Input";
import DropdownList from "../../UI/Form/DropdownList";
import TabTitleSVG from "../../UI/TabTitleSVG";
import DatePicker from "react-datepicker";
import {getFormsContext} from "../../../api/formsContextApi";
import classes from "./PatientForm.module.css";
import {CardBody} from "../../../../_metronic/_partials/controls";
import useInput from "../../../hooks/use-input";

const isNotEmpty = (value) => value.toString().trim() !== "";
// const initialOptionsModel={gender: [],}
const UserForm = ({patient, id, savePatient, actionsLoading}) => {
    const history = useHistory();
    console.log("reEvaluate Form");//console.log(patient);
    const [initialOptions, setInitialOptions] = useState({});
    //  Setters for fields
    const {
        value: surname,
        isValid: surnameIsValid,
        hasError: surnameHasError,
        touchField: surnameValidate,
        inputBlurHandler: surnameBlurHandler,
    } = useInput(isNotEmpty, patient.lastName || '');
    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        touchField: nameValidate,
        inputBlurHandler: nameBlurHandler,
    } = useInput(isNotEmpty, patient.firstName || '');

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        touchField: emailValidate,
        inputBlurHandler: emailBlurHandler,
    } = useInput(isNotEmpty, patient.email || '');
    const {
        value: phone,
        isValid: phoneIsValid,
        hasError: phoneHasError,
        touchField: phoneValidate,
        inputBlurHandler: phoneBlurHandler,
    } = useInput(isNotEmpty, patient.phone || '');
    const {
        value: enteredId,
        isValid: idIsValid,
        hasError: idHasError,
        touchField: idValidate,
        inputBlurHandler: idBlurHandler,
    } = useInput(isNotEmpty, patient.id || '');

    const [dropdownsState, setDropdownsState] = useState({
        gender: patient.gender || '',
    });
    const [birthDate, setBirthDate] = useState(patient.birthDate ? new Date(patient.birthDate) : '');

    useEffect(() => {
        if (id) {
            setDropdownsState({
                gender: patient.gender || ''
            });
            if (patient.birthDate) {
                setBirthDate(new Date(patient.birthDate));
            }
        }
        // console.log("PatientLoaded:", patient)
    }, [patient, id])

    // Update Initial Options from request  e.g. gender=[male,female]
    useEffect(() => {
        const fetchData = async () => {
            await getFormsContext().then((response) => {
                console.log("Update Initial Options")
                if(response && response.data && response.data.gender)
                    setInitialOptions({...response.data});
            }).catch((error) => {
                console.log(error);
            });
        };
        fetchData();    //TODO Handle Response  (try/catch?)

        // return () => {
        // }
    }, [])

    //  Handlers
    const dropdownChangeHandler = (event) => {
        setDropdownsState({...dropdownsState, [event.target.name]: event.target.value});
    };
    const dateChangeHandler = (event) => {
        console.log("Date:",event, new Date(event))
        setBirthDate(event)
    };
    const submitHandler = (event) => {
        event.preventDefault();
        const formIsValid = nameIsValid && surnameIsValid && emailIsValid && phoneIsValid;

        if (formIsValid) {

            savePatient({
                //id: +enteredId,
                lastName: surname,
                firstName: name,
                email: email,
                phone: phone,
                birthDate,
                gender: dropdownsState.gender,

                // status: patient.status,
                // type: patient.type//**************
            });
            //goBack() //replace("/patients");
        } else {
            surnameValidate();
            nameValidate();
            emailValidate();
            phoneValidate()
        }

        console.log('Personal data: ', {
            surname: surname, name: name,//email: enteredEmail,
            //phone: enteredPhone,
            birthDate, gender: dropdownsState.gender, //id: enteredId,
        });

    }

    const goBack = ()=>{
        history.goBack()
    }
    const PersonalInformation = <>
        <Input label='Επώνυμο' value={surname} onBlur={surnameBlurHandler} hasError={surnameHasError}
               input={{
                   id: 'Επώνυμο',
                   type: 'text',
                   placeholder: "Επώνυμο",
                   name: 'surname',
                   required: true
               }}
        />
        <Input label='Όνομα' value={name} onBlur={nameBlurHandler} hasError={nameHasError}
               input={{
                   id: 'Όνομα',
                   type: 'text',
                   name: 'name',
                   placeholder: "Όνομα",
                   required: true
               }}
        />
        <Input label='Email' value={email} onBlur={emailBlurHandler} hasError={emailHasError}
               input={{
                   id: 'email',
                   type: 'email',
                   name: 'email',
                   // readOnly:true,
                   placeholder: "name@example.com",
                   required: true
               }}
        />
        <Input label='Τηλέφωνο' value={phone} onBlur={phoneBlurHandler} hasError={phoneHasError}
               input={{
                   id: 'Τηλέφωνο',
                   type: 'text',
                   name: 'phone',
                   placeholder: "6912345678",
                   required: true
               }}
        />

        <Form.Group controlId="Ημερομηνία γέννησης">
            <Form.Label>Ημερομηνία γέννησης</Form.Label>
            <div className={classes.datepickerDiv}>
                <DatePicker
                    selected={birthDate}
                    dateFormat="dd/MM/yyyy"
                    id='birthDate'
                    className="form-control"
                    onChange={dateChangeHandler}
                />
            </div>
        </Form.Group>

        <DropdownList label="Φύλο" id="Φύλο" name="gender" value={dropdownsState.gender} onBlur={dropdownChangeHandler}
                      items={initialOptions.gender}/>
    </>
    const GeneralInformation = <>
        <Input label='Κωδικός' value={enteredId} onBlur={idBlurHandler} hasError={idHasError}
               input={{
                   id: 'Κωδικός',
                   type: 'number',
                   name: 'id',
                   placeholder: "Κωδικός",
                   disabled:true
               }}
        />
    </>

    return < >

        {
            // !isLoading &&
            <Form className="card card-custom ">
                <div className="card-header py-3">
                    {/*Tabs*/}
                    <div className="card-title align-items-start flex-row flex-wrap">
                        <TabTitleSVG isActive={true} onClick={() => {
                        }}
                                     title='Προσωπικές / Γενικές πληροφοριές'
                                     absoluteUrl='/media/svg/icons/General/User.svg'/>
                    </div>
                    {/*Action buttons*/}
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            onClick={submitHandler}
                            disabled={null}
                            // formik.isSubmitting || (formik.touched && !formik.isValid)
                            //}
                        >
                            Αποθήκευση
                            {/*{formik.isSubmitting}*/}
                        </button>
                        <button type="button" onClick={goBack} className="btn btn-secondary">
                            Ακύρωση
                        </button>
                    </div>
                </div>
                <div className="form">
                    {/* begin::Body */}
                    <CardBody className={classes.card_body}>
                        {actionsLoading &&
                        <div className="overlay-layer bg-transparent">
                            <div className="spinner spinner-lg spinner-success"/>
                        </div>
                        }
                        {!actionsLoading && <>
                            <div className={classes.form_column}>
                                {PersonalInformation}
                            </div>
                            <div className={classes.form_column}>
                                {GeneralInformation}
                            </div>
                        </>}

                    </CardBody>
                    {/* end::Body */}
                </div>

            </Form>}
    </>;

}
export default UserForm;
