import * as requestFromServer from "../../app/api/planExercisesApi";
import {planExercisesSlice, callTypes} from "./planExercisesSlice";

const {actions} = planExercisesSlice;

export const fetchPlanExercises = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .getAllExercisesByPlanId(id)
        .then(async response => {
            const data = await response.json()
            // console.log(data)
            // console.log(response.data)
            // const { totalCount, entities } = response.data;
            //   dispatch(actions.plansFetched({ totalCount, entities }));
            dispatch(actions.planExercisesFetched({totalCount: data.length, entities: data}));
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};
export const fetchPlansFiltered = queryParams => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .findPlanExercises(queryParams)
        .then(async response => {
            const data = await response.json()
            console.log(data)
            dispatch(actions.planExercisesFetched({totalCount: data.length, entities: data}));
        })
        .catch(error => {
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

export const fetchPlanExercise = id => dispatch => {
    if (!id) {
        return dispatch(actions.planFetched({planExerciseForEdit: undefined}));
    }
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getPlanExerciseById(id)
        .then(async response => {
            const data = await response.json()
            // const plan = response.data;
            // console.log(data)

            dispatch(actions.planExerciseFetched({planExerciseForEdit: data}));
        })
        .catch(error => {
            error.clientMessage = "Can't find customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const deletePlanExercise = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .deletePlanExercise(id)
        .then(response => {
            dispatch(actions.planExerciseDeleted({id}));
        })
        .catch(error => {
            error.clientMessage = "Can't delete customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};
//
export const createPlanExercise = planExerciseForCreation => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    console.log('Actions Creation')
    return requestFromServer
        .createPlanExercise(planExerciseForCreation)
        .then(async response => {
            const data = await response.json()

            dispatch(actions.planExerciseCreated({newPlan:data}));
        })
        .catch(error => {
            error.clientMessage = "Can't create plExercise";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};
export const updatePlanExercises = updatedPlExercise => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .updatePlanExerciseOrder(updatedPlExercise)
        .then(async response => {
            const data = await response.json()
            // console.log(data)
            // console.log(response.data)
            dispatch(actions.planExercisesUpdated({data}));
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};
//
// export const updatePlan = plan => dispatch => {
//     dispatch(actions.startCall({callType: callTypes.action}));
//     return requestFromServer
//         .updatePlan(plan)
//         .then(() => {
//             dispatch(actions.planUpdated({plan}));
//         })
//         .catch(error => {
//             error.clientMessage = "Can't update customer";
//             dispatch(actions.catchError({error, callType: callTypes.action}));
//         });
// };
//
// export const updatePlansStatus = (ids, status) => dispatch => {
//     dispatch(actions.startCall({callType: callTypes.action}));
//     return requestFromServer
//         .updateStatusForPlans(ids, status)
//         .then(() => {
//             dispatch(actions.plansStatusUpdated({ids, status}));
//         })
//         .catch(error => {
//             error.clientMessage = "Can't update customers status";
//             dispatch(actions.catchError({error, callType: callTypes.action}));
//         });
// };
//
// export const deletePlans = ids => dispatch => {
//     dispatch(actions.startCall({callType: callTypes.action}));
//     return requestFromServer
//         .deletePlans(ids)
//         .then(() => {
//             dispatch(actions.plansDeleted({ids}));
//         })
//         .catch(error => {
//             error.clientMessage = "Can't delete customers";
//             dispatch(actions.catchError({error, callType: callTypes.action}));
//         });
// };
