import React from "react";
import {Route, useLocation, useRouteMatch} from "react-router-dom";
import ExercisesPlanTemplatesList from "./ExercisesDashboard/ExercisesPlanTemplatesList";
import ExercisesPlanTemplatesListCopy from "./ExercisesDashboard/ExercisesPlanTemplatesListCopy";
import ExercisesPlansList from "./ExercisesDashboard/ExercisesPlansList";
import isAuthorized from "../../helpers/AuthorizedFunction";
import classes from "./ExercisesDashboard.module.css";

import {useParams} from "react-router";

const ExercisesDashboard = () => {

        // const [plansList,setPlansList] = useState([])
        const match = useRouteMatch();
        const params = useParams();
        const loc = useLocation();

        // const {params} = match
        //
        // const planId = params.planId;
        console.log({params})
        console.log({match})
        console.log({loc})

        const isNotPatient = isAuthorized(["therapist", 'admin'])

        return (<>
                <div className={classes['dashboard-container']}>
                    <Route path={`${match.path}`}>
                        <ExercisesPlansList/>
                    </Route>
                    <div className={classes.exercisesSection}>
                        <Route path={`${match.path}/:planId`} exact>
                            <ExercisesPlanTemplatesList/>
                            <ExercisesPlanTemplatesListCopy/>
                        </Route>
                    </div>


                </div>
            </>
        );
    }
;

export default ExercisesDashboard;