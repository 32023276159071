import axios from "axios";

export const INITIAL_OPTIONS_URL = "api/formContext";

// const PLANS_URL_ESDA= `${process.env.REACT_APP_ESDA_API}/api/v1/plans`;
const ESDA_API = process.env.REACT_APP_ESDA_API ? process.env.REACT_APP_ESDA_API : ''
const PATIENT_URL_ESDA= `${ESDA_API}/api/v1/users`;

// READ
export function getFormsContext() {
  return axios.get(`${PATIENT_URL_ESDA+ "/Options"}`);
  // return fetch(`${PATIENT_URL_ESDA+ "/Options"}`)
}