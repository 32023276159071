import {createSlice} from "@reduxjs/toolkit";

const initialPlanExercisesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  planExerciseForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const planExercisesSlice = createSlice({
  name: "planExercises",
  initialState: initialPlanExercisesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    planExerciseFetched: (state, action) => {
      state.actionsLoading = false;
      state.planExerciseForEdit = action.payload.planExerciseForEdit;
      state.error = null;
    },
    planExerciseCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.planExerciseForEdit = action.payload.planExerciseForEdit;
      // replaced bottom with dispacth fetchExercises in UI
      // state.entities.push(action.payload.newPlan);
    },
    // // findCustomers
    planExercisesFetched: (state, action) => {
      console.log(action.payload)
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    planExercisesUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const comprehension = updateGroupExerciseArray(state.entities['COMPREHENSION'],action.payload.data)
      const wp = updateGroupExerciseArray(state.entities['WORD_PRODUCTION'],action.payload.data)
      const intonation = updateGroupExerciseArray(state.entities['INTONATION'],action.payload.data)
      console.log(state.entities.COMPREHENSION)
      state.entities = {
        ...state.entities,
        "COMPREHENSION": comprehension,
        "WORD_PRODUCTION":wp,
        'INTONATION':intonation
      }
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.data[0].id) {
      //     return action.payload.data[1];
      //   }
      //   if (entity.id === action.payload.data[1].id) {
      //     return action.payload.data[0];
      //   }
      //   return entity;
      // });
    },
    // createCustomer
    planCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.newPlan);
    },
    // updateCustomer
    // planUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map(entity => {
    //     if (entity.id === action.payload.plan.id) {
    //       return action.payload.plan;
    //     }
    //     return entity;
    //   });
    // },
    // // deletePlanExercise
    planExerciseDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // const deletedIndex = state.entities.findIndex(el => el.id === action.payload.id)
      const comprehension = deleteExerciseFromArray(state.entities['COMPREHENSION'],action.payload.id)
      const wp = deleteExerciseFromArray(state.entities['WORD_PRODUCTION'],action.payload.id)
      const intonation = deleteExerciseFromArray(state.entities['INTONATION'],action.payload.id)
      // console.log({deletedIndex})
      state.entities = {
        ...state.entities,
        "COMPREHENSION": comprehension,
        "WORD_PRODUCTION":wp,
        'INTONATION':intonation
      }
      // state.entities = state.entities
      //     .filter(el => el.id !== action.payload.id)
      //     .map( (el, i) => ( i < deletedIndex ) ? el : {...el, order: el.order - 1 });
    },
    // // deleteCustomers
    // plansDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },
    // // customersUpdateState
    // plansStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  }
});


const updateGroupExerciseArray = (array,itemsUpdated) => array.map(entity => {
  if (entity.id === itemsUpdated[0].id) {
    return itemsUpdated[0];
  }
  if (entity.id === itemsUpdated[1].id) {
    return itemsUpdated[1];
  }
  return entity;
}).sort((a,b)=> {
  return a.order - b.order
});

const deleteExerciseFromArray = (array,idDelete) => {
  const deletedIndex = array.findIndex(el => el.id === idDelete)
  // console.log({deletedIndex})
   return array.filter(entity => idDelete !== entity.id)
      .map( (el, i) => ( i < deletedIndex ) ? el : {...el, order: el.order - 1 }); }
