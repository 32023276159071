import axios from "axios";

const KEYCLOAK_URL = `${process.env.REACT_APP_KEYCLOAK_SERVER}/auth/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`
// export const PATIENTS_URL = "api/patients";
const ESDA_API = process.env.REACT_APP_ESDA_API ? process.env.REACT_APP_ESDA_API : ''
const PATIENTS_URL =  `${ESDA_API}/api/v1/users`;

// CREATE =>  POST: add a new customer to the server
export function createKeycloakUser(user) {
    console.log({keycloakUserToCreate: user})
    const post_data =
        {
            "username": user.username,
            "email": user.email,
            "firstName": user.firstName,
            "lastName": user.lastName,
            "credentials": [{
                "type": "password",
                "value": user.password,
                "temporary": user.type === "therapist" && true
            }],
            "enabled": true,
            "attributes": {
                "type": user.type || "patient",
                "created_by": user.created_by
            },
            // "realmRoles": ['therapist']
            "groups": user.type ? ["therapists"] : undefined
            // "clientRoles": {"realm-management":["view-clients"]}
        }
    return axios.post(KEYCLOAK_URL, post_data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function createEsdaUser(patient_id, username, therapist_id, type) {
    console.log({patient_id, username, therapist_id})
    const post_data = {
        "username": username,
        "keycloak_id": patient_id,
        "created_by": therapist_id,
        "type": type || "patient",
        "enabled": true
    }
    return axios.post(PATIENTS_URL, post_data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// READ
export function getAllPatients() {
  return axios.get(`${KEYCLOAK_URL}?briefRepresentation=true`);
}

export function getEsdaUserByKeycloakId(patientId) {
  return axios.get(`${PATIENTS_URL}/${patientId}`);
}

export function getUserById(patientId) {
  return axios.get(`${KEYCLOAK_URL}/${patientId}`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getUserByUsername(username) {
  return axios.get(`${KEYCLOAK_URL}?username=${username}&exact=true`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
  console.log({queryParams})
  let first=0
  first = queryParams.pageNumber > 1 ? (queryParams.pageNumber - 1) * queryParams.pageSize : 0
  const pathParams = `briefRepresentation=false&first=${first}&max=${queryParams.pageSize}${queryParams.filter.email?"&search="+queryParams.filter.email:""}`
  return axios.get(`${KEYCLOAK_URL}?${pathParams}`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });
  // return axios.post(`${PATIENTS_URL}/find`, { queryParams });
}

export function findUsersEsda(queryParams) {
    console.log({...queryParams})
    // let first = 0
    // first = queryParams.pageNumber > 1 ? (queryParams.pageNumber - 1) * queryParams.pageSize : 0
    // const pathParams = `briefRepresentation=false&first=${first}&max=${queryParams.pageSize}${queryParams.filter.email ? "&search=" + queryParams.filter.email : ""}`
    return axios.post(`${PATIENTS_URL}/find`, {...queryParams}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    // return axios.post(`${PATIENTS_URL}/find`, { queryParams });
}

export function countUsers(queryParams) {
    const pathParams = `${queryParams.filter.email ? "&search=" + queryParams.filter.email : ""}`
    return axios.get(`${KEYCLOAK_URL}/count?${pathParams}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    // return axios.post(`${PATIENTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateUser(patient) {
    let updated_data = {"email": patient.email, "firstName": patient.firstName, "lastName": patient.lastName}
    // updated_data = patient.email ? {...updated_data, "email" :patient.email} : updated_data;
    // updated_data = patient.email ? {...updated_data, "firstName" :patient.name} : updated_data;
    // updated_data = patient.email ? {...updated_data, "lastName" :patient.surname} : updated_data;
    console.log({patient, updated_data})
    return axios.put(`${KEYCLOAK_URL}/${patient.id}`, updated_data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// export function updateEsdaUserStatus(id, objState) {
//   return axios.put(`${PATIENTS_URL}/${id}`, { ...objState });
// }
export function updatePatient(id, patient) {
  return axios.put(`${PATIENTS_URL}/${id}`, { ...patient });
}

// UPDATE Status
export function updateStatusForPatients(ids, status) {
  return axios.post(`${PATIENTS_URL}/updateStatusForPatients`, {
    ids,
    status
  });
}

export function resetKeycloakPassword(user) {
    let updated_data = {
        // * CredentialRepresentation *  /reset-password
        "type":"password",
        "temporary": user.temporary || false,
        "value":user.password
    }
    console.log({user, updated_data})
    return axios.put(`${KEYCLOAK_URL}/${user.id}/reset-password`, updated_data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// DELETE => delete the customer from the server
export function deleteKeycloakUser(patientId) {
  console.log("patientsCRUD(API) --> deletePatient : ",patientId)
  return axios.delete(`${KEYCLOAK_URL}/${patientId}`);
}

// DELETE Customers by ids
export function deletePatients(ids) {
  return axios.post(`${PATIENTS_URL}/deletePatients`, { ids });
}
