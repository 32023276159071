import {createSlice} from "@reduxjs/toolkit";

const initialExercisesState = {
    status: null,
    listLoading: false,
    actionsLoading: false,
    // lastError: null,
    // totalChoices: 0,
    exerciseData: null,
    // videos: null,
    // exerciseType: null,
    isStepCompleted: false,
    exerciseIsCompleted: false,
    attempts: null,
};
export const callTypes = {
    list: "list",
    action: "action",
    exercise: "exercise",
    step: "step",
    section: "section",

};
const SHOW_LOGS = false
// const URL_GET_CAT = "http://planv.atlas.esdalab.ece.uop.gr/api/v1/getlang1video/av_1_gata.wav.mp4";
const URL_GET_INSTRUCTION = "https://planv.atlas.esdalab.ece.uop.gr/api/v1/getlang1video/av_instructions.wav.mp4";
const URL_GET_INDICATE_CORRECT = "https://planv.atlas.esdalab.ece.uop.gr/api/v1/getlang1video/av_template_1_this_is_the_right_picture.wav.mp4";
const POSITIVE_FEEDBACKS = [
    'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/bravo.mp4',
    'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/poly_kala.mp4',
    'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/swsta.mp4',
    'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/wraia.mp4',
    // 'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/eksairetika.mp4',
    // 'https://planv.atlas.esdalab.ece.uop.gr/api/v1/getvideo/feedbacks/ta_pigainete_poly_kala.mp4 ',
]

export const exercisesSlice = createSlice({
        name: "exercises",
        initialState: initialExercisesState,
        reducers: {
            initializeExercise1: (state, action) => {
                state.exerciseLoading = false;
                state.status = "start_instructions"
                state.hasRemainingTime = true;
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                state.exerciseData = null;
                state.exerciseSubtitle = undefined;
                state.questionTitle = undefined;
                state.results = undefined;

                // attempts,  exerciseType, video_instruction, exerciseTitle
                const {exerciseData, template_id} = action.payload;
                const {attempts, type, video_instruction, title} = exerciseData;
                // state.avatarUrl = exerciseData.video_target?[video_instruction,exerciseData.video_target]:[video_instruction];

                let avatar = [video_instruction]
                if(exerciseData.video_target)
                    avatar = [...avatar, exerciseData.video_target]
                if (exerciseData.video_target_sentence)
                    avatar = [...avatar, exerciseData.video_target_sentence]
                if (exerciseData.target_video_description)
                    avatar = [...avatar, exerciseData.target_video_description]
                if (exerciseData.questionVideo)
                    avatar = [...avatar, exerciseData.questionVideo]

                state.avatarUrl = avatar;

                state.exerciseType = type;
                state.attempts = attempts;
                state.exerciseTitle = title;
                state.exerciseData = exerciseData;
                state.templateId = template_id;
            },
            startExercise1: (state, action) => {
                if (SHOW_LOGS) console.log({payload:action.payload});

                state.stepLoading = false;
                state.isStepCompleted = false;
                state.status = "start_exercise"
                state.results = undefined;
                // state.status = "next_step"
                // state.hasRemainingTime = true;
                // state.avatarUrl = ''
                // state.questionTitle = undefined;

                const {exerciseData} = action.payload;
                state.videos = {...state.videos,video_target:exerciseData.video_target};

                const video_target = exerciseData.video_target;// state.exerciseData && state.exerciseData.video_target ? state.exerciseData.video_target : '';

                if(exerciseData.video_target)
                    state.avatarUrl = exerciseData.video_target
                if(exerciseData.video_target_sentence && exerciseData.questionVideo)
                    state.avatarUrl= [exerciseData.video_target_sentence, exerciseData.questionVideo ];
                else if (exerciseData.video_target_sentence)
                    state.avatarUrl= exerciseData.video_target_sentence;
                else if (exerciseData.target_video_description)
                    state.avatarUrl= exerciseData.target_video_description;
                else if (exerciseData.questionVideo)
                    state.avatarUrl= exerciseData.questionVideo;

                state.exerciseType = state.exerciseData.type;
                state.attempts = exerciseData.attempts;
                state.videos = {...state.videos,video_target: video_target};//'/video/1.1'}; //URL_GET_CAT //video_target};
                if (exerciseData) {
                    state.exerciseData = exerciseData;
                }
                if (exerciseData && exerciseData.questionTitle) {
                    state.questionTitle = exerciseData.questionTitle;
                }
            },
            initializeExerciseWP: (state, action) => {
                state.exerciseLoading = false;
                state.stepLoading = false;
                state.status = "start_instructions"
                // state.hasRemainingTime = true;
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                // state.exerciseData = null;
                state.exerciseSubtitle = undefined;
                state.questionTitle = undefined;
                state.results = undefined;

                //*****************
                const {data,template_id} = action.payload ;     //  { data: {} , template_id: number }

                state.avatarUrl = data.video_target_sentence ? [data.video_instruction_1,data.video_target_sentence] : data.video_instruction_1;
                state.exerciseData = data;
                state.templateId = template_id;
                // state.exerciseType = exerciseType;
                // state.exerciseTitle = exerciseTitle;
            },
            initializeExercise1to12: (state, action) => {
                state.exerciseLoading = false;
                state.status = "start_instructions"
                state.hasRemainingTime = true;
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                state.exerciseData = null;
                state.exerciseSubtitle = undefined;
                state.questionTitle = undefined;

                const {attempts, exerciseType, videos, exerciseTitle} = action.payload;

                state.avatarUrl = URL_GET_INSTRUCTION; //TODO change video_instruction videos.avatar_play;
                // state.instructionAvatar = videos.instruction;
                state.exerciseType = exerciseType;
                state.attempts = attempts;
                state.exerciseTitle = exerciseTitle;
            },
            startExercise1to12: (state, action) => {
                state.stepLoading = false;
                state.status = "start_exercise"

                // const {attempts, exerciseType, videos, exerciseTitle} = action.payload;
                const {attempts, exerciseType, videos, exerciseData} = action.payload;

                state.avatarUrl = videos.avatar_play ? videos.avatar_play : (videos.target ? videos.target : '');
                state.exerciseType = exerciseType;
                state.attempts = attempts;
                state.videos = videos;
                if (exerciseData) {
                    state.exerciseData = exerciseData;
                }
                if (exerciseData && exerciseData.questionTitle) {
                    state.questionTitle = exerciseData.questionTitle;
                }
                //
                // state.targetAvatar = videos.target?videos.target:'';
                // state.exerciseType = exerciseType;
                // state.attempts = attempts;
                // state.exerciseTitle = exerciseTitle;
            },
            nextStep1to12: (state) => {
                state.status = "next_step"
                state.hasRemainingTime = true;
                state.isStepCompleted = false;
                state.stepLoading = true;
                state.avatarUrl = ''
                state.questionTitle = undefined;
                // const {exerciseData, question_title, choices, has_next_tab} = action.payload;
                //
                // if (state.exerciseData) {
                //     console.log(exerciseData)
                //     state.exerciseData.videos = {...state.exerciseData.videos, ...exerciseData.videos};
                // }
            },
            startStepWP: (state, action) => {
                state.status = "start_next_step"
                state.exerciseLoading = false;
                state.stepLoading = false;

                state.isStepCompleted = false;
                state.stepLoading = true;
                // state.avatarUrl = ''
                // state.questionTitle = undefined;

                const {exerciseData, attempts, video_instruction, exerciseTitle} = action.payload;
                // console.log({payload: action.payload});

                state.exerciseTitle = exerciseTitle ;
                state.videos = {...state.videos, video_instruction};
                if (exerciseData) {
                    state.exerciseData = exerciseData;
                }
            },
            // T13, T14-17
            initializeExercise13to17: (state, action) => {
                // state.actionsLoading = false;
                // state.error = null;
                state.exerciseLoading = false;
                // state.listLoading = false;
                state.status = "start_record_step"
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                state.exerciseData = null;
                // attempts, type: exerciseType,title:exerciseTitle, videos
                const {
                    exerciseData,
                } = action.payload;
                // const {
                //     attempts,
                //     type: exerciseType,
                //     videos,
                //     title:exerciseTitle,
                //     exerciseSubtitle ,
                // } = exerciseData;
                console.log(action.payload)
                // const {attempts, exerciseType, videos, exerciseTitle} = action.payload;
                // state.avatarUrl = exerciseData.exercise_instruction ? exerciseData.exercise_instruction : '';
                state.avatarUrl = exerciseData.exercise_instruction || exerciseData.exercise_instruction_1;

                // state.exerciseType = exerciseType;
                if (exerciseData) {
                    state.exerciseData = exerciseData;
                }
                // if (state.exQuestions) {
                //     state.exQuestions = null;
                // }
                // if (attempts) {
                //     state.attempts = attempts;
                // }
            },
            // T13, T16, T19
            initializeGeneralExercise: (state, action) => {
                state.exerciseLoading = false;
                state.status = "start_instructions"
                state.hasRemainingTime = true;
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                state.exerciseData = null;
                state.exerciseSubtitle = undefined;
                state.questionTitle = undefined;

                const {exerciseData,template_id} = action.payload;
                // attempts,  exerciseType, video_instruction, title
                const {attempts,  type, video_instruction, title}=exerciseData
                // state.avatarUrl = exerciseData.video_target?[video_instruction,exerciseData.video_target]:[video_instruction];

                let avatar = [video_instruction]
                if(exerciseData.video_target)
                    avatar = [...avatar, exerciseData.video_target]
                if(exerciseData.video_target_sentence)
                    avatar=[...avatar, exerciseData.video_target_sentence]
                if(exerciseData.questionVideo) {
                    // the below is to support  questionVideo="url" and questionVideo=[url,url2] from BE data
                    const questionVideoToArray = exerciseData.questionVideo.constructor === Array ? exerciseData.questionVideo :[exerciseData.questionVideo];
                    avatar = [...avatar, ...questionVideoToArray]
                }

                state.avatarUrl = avatar;
                state.videos = {
                    ...state.videos,
                    video_instruction,
                    video_target: exerciseData.video_target,
                    video_target_sentence: exerciseData.video_target_sentence
                }; //TODO change video_instruction
                // state.instructionAvatar = videos.instruction;
                state.exerciseType = type;
                state.attempts = attempts;
                state.exerciseTitle = title;
                state.exerciseData = exerciseData;
                state.templateId = template_id;
            },
            startExerciseSP2: (state, action) => {
                if (SHOW_LOGS) console.log({payload:action.payload});

                state.stepLoading = false;
                state.isStepCompleted = false;
                state.status = "start_exercise"

                const {exerciseData} = action.payload;

                if(exerciseData.video_instruction && exerciseData.questionVideo) {
                    // the below is to support  questionVideo="url" and questionVideo=[url,url2] from BE data
                    const questionVideoToArray = exerciseData.questionVideo.constructor === Array ? exerciseData.questionVideo : [exerciseData.questionVideo];
                    state.avatarUrl = [exerciseData.video_instruction, ...questionVideoToArray];
                } else if (exerciseData.questionVideo)
                    state.avatarUrl = exerciseData.questionVideo;

                state.exerciseType = state.exerciseData && state.exerciseData.type;
                state.attempts = exerciseData.attempts;

                if (exerciseData) {
                    state.exerciseData = exerciseData;
                }
                if (exerciseData && exerciseData.title) {
                    state.exerciseTitle = exerciseData.title;
                }
                if (exerciseData && exerciseData.questionTitle) {
                    state.questionTitle = exerciseData.questionTitle;
                }
            },
            initializeSpeechExercise: (state, action) => {
                if (SHOW_LOGS) console.log({payload:action.payload});

                state.status = "start_exercise"
                state.isStepCompleted = false;
                state.exerciseIsCompleted = false;
                const {steps,stepsNumber} = action.payload;
                state.steps = steps;
                state.stepsNumber = stepsNumber;
                if (steps.length > 0){
                    state.avatarUrl = steps[0].avatar;
                    state.avatarDemo = steps[0].animation;
                }
                //  NOT USED
            },
            nextSpeechSection: (state, action) => {
                if (SHOW_LOGS) console.log({payload:action.payload});

                // state.status = "start_exercise"
                // state.isCompleted = false;
                const {steps,stepsNumber} = action.payload;
                state.steps = steps;
                state.stepsNumber = stepsNumber;
                state.sectionLoading = false;
            },
            nextSpeechStep: (state, action) => {
                // const {id} = action.payload;
                state.stepLoading = false;
                // if (state.steps && state.steps.length > 0){
                //     state.avatarUrl = state.steps[id].avatar;
                //     state.avatarDemo = state.steps[id].animation;
                //     state.picture = state.steps[id].picture;
                //     state.instructions = state.steps[id].instructions;
                // }
            },
            // answerSent: (state, action) => {
            // },
            // zeroAttempts:()=>{},
            clickCorrectAnswer: (state, action) => {
                state.status = "correct";   //χρησιμοποιειται στο t16 ισως αλλαχθει
                const num = Math.floor(Math.random() * POSITIVE_FEEDBACKS.length)
                if(action.payload.avatarResponse){
                    state.avatarUrl = POSITIVE_FEEDBACKS[num]; //action.payload.avatarResponse;
                } else{
                    state.avatarUrl = POSITIVE_FEEDBACKS[num];
                }
                if(state.exerciseData && state.exerciseData.play_after_show_correct){
                    state.avatarUrl = [state.avatarUrl,state.exerciseData.play_after_show_correct]
                }
                if(action.payload.isStepCompleted){
                    state.isStepCompleted = true;
                }
            },
            clickWrongAnswer: (state, action) => {
                state.status = 'wrong'
                state.attempts = state.attempts ? state.attempts-1 : state.attempts;
                if (state.attempts === 0) {
                    state.isStepCompleted = true;
                }
                if (action.payload.avatarResponse) {
                    state.avatarUrl = action.payload.avatarResponse;
                }
                if (state.attempts < 1 && state.attempts!==null) {
                    state.avatarUrl = state.exerciseData.indicate_correct ? [state.exerciseData.indicate_correct] : '';
                    // SP_2A specific
                    if(state.exerciseData && state.exerciseData.play_after_show_correct){
                        state.avatarUrl = [...state.avatarUrl,state.exerciseData.play_after_show_correct];
                    }
                }

            },
            timerExpired: (state) => {
                state.status = "time_expired"
                state.hasRemainingTime = false;
                state.isStepCompleted = true;
                if (state.exerciseData && state.exerciseData.indicate_correct ){
                    state.avatarUrl = state.exerciseData.indicate_correct;
                }
            },
            stepCompleted: (state) => {
                state.isStepCompleted = true;
            },
            changeAvatar: (state, action) => {
                state.avatarUrl = action.payload.url;
                state.avatarLoading = true;
                // state.avatarReloadFlag = Math.round(Date.now() / 1000);
            },
            avatarLoaded: (state, action) => {
                state.avatarLoading = false;
            },
            changeDemoAvatar: (state, action) => {
                state.avatarDemo = action.payload.url;
            },
            addInState: (state, action) => {
                // state[action.payload.data.state]= action.payload.data.value;
                if (!state.exerciseData){
                    state.exerciseData=action.payload.data
                }else{
                    state.exerciseData[action.payload.data.state]= action.payload.data.value;
                }
            },
            updateState: (state, action) => {
                    state[action.payload.data.state]= action.payload.data.value;
            },
            addInResults: (state, action) => {
                if (!state.results) {
                    state.results = action.payload.data
                } else {
                    state.results = {...state.results, ...action.payload.data}
                }
            },
            addHelpInResults: (state, action) => {
                if (!state.results) {
                    state.results = {helps: action.payload.data}
                } else {
                    if (!state.results.helps)
                        state.results.helps = {...action.payload.data}
                    else
                        state.results.helps = {...state.results.helps, ...action.payload.data}
                }
            },
            saveAttemptInResults: (state, action) => {
                const newAttempt = action.payload.data
                const attempts = state.results && state.results.attempts ? [...state.results.attempts, newAttempt] : [newAttempt];
                if (!state.results) {
                    state.results = {attempts}
                } else {
                    state.results.attempts = attempts;
                }
            },
            nextExercise: (state) => {
                state.hasRemainingTime = true;
                state.isStepCompleted = false;//exerciseIsCompleted
                state.entities = null;
                state.attempts = null;
                state.videos = null;
                state.avatarUrl = ''
            },
            nextWPSubStep: (state) => {
                state.isStepCompleted = false;
                // state.attempts = null;
                // state.avatarUrl = ''
            },
            setQuestion: (state, action) => {
                state.status = "question";
                // if (choices) {
                    state.questionData = action.payload;
                // }
            },
            setL2Question: (state, action) => {
                state.status = "question";
                const {exerciseData} = action.payload;
                const {videos, question_title, choices, correct_answer, image_description_id,correct_response} = exerciseData;
                if(state.exerciseData ){
                    if(state.exerciseData.videos) {
                        state.exerciseData.videos = {...state.exerciseData.videos, ...videos};
                    }else{
                        state.exerciseData.videos = {...videos};
                    }
                }else{
                    state.exerciseData=videos
                }
                if (question_title) {
                    state.questionTitle = question_title;
                }
                if (choices) {
                    state.questionData = { choices};
                }
                // if (videos && videos.questions) {
                //     state.avatarUrl = videos.questions
                //     state.questionInstruction = videos.questions[0]
                // }
                if (videos ) {
                    console.log(videos)
                    state.avatarUrl = videos.avatar_play
                    state.questionInstruction = videos.questionInstruction
                }
                if (correct_answer) {
                    state.questionData = {...state.questionData, correct_answer};
                }
                if (correct_response) {
                    state.questionData = {...state.questionData, correct_response};
                }
                if (image_description_id) {
                    state.questionData = {...state.questionData, image_description_id};
                }
            },
            setL2Questions: (state, action) => {
                state.listLoading = false;
                const {exerciseData, exerciseTitle, exerciseSubtitle} = action.payload;
                if(state.exerciseData && state.exerciseData.videos){
                    state.exerciseData.videos = {...state.exerciseData.videos, ...exerciseData.videos};
                }else{
                    state.exerciseData.videos = {...exerciseData.videos};
                }
                state.exQuestions = exerciseData.exQuestions;
                state.exerciseTitle = exerciseTitle;
                state.exerciseSubtitle = exerciseSubtitle;
                if (exerciseData.videos && exerciseData.videos.instruction) {
                    state.avatarUrl = exerciseData.videos.instruction
                    state.instructionAvatar = exerciseData.videos.instruction
                }
            },
            setL2Avatars: (state, action) => {
                const {videos} = action.payload;
                console.log(action.payload)
                state.avatarUrl = videos.instruction;
                // state.exerciseData.videos = {...state.exerciseData.videos, ...exerciseData.videos};
            },
            setL2StepRecordingWords: (state, action) => {
                const {videos,subtitle} = action.payload;
                state.exerciseSubtitle = subtitle;
                state.avatarUrl = videos.instruction;
                state.instructionAvatar = videos.instruction[1]
            },
            clearState: (state) => {
                state.status = null;
                state.listLoading = false;
                state.actionsLoading = false;
                state.exerciseData = null;
                state.videos = null;
                state.exerciseType = null;
                state.isStepCompleted = false;//&& exerciseIsCompleted
                state.attempts = null;
                state.exerciseTitle = undefined;
                state.templateId = undefined;
            },
            clearQuestion: (state) => {
                state.questionData = undefined;
                state.questionInstruction = undefined;
                state.questionTitle = undefined;

            },
            catchError: (state, action) => {
                state.error = `${action.type}: ${action.payload.error}`;
                if (action.payload.callType === callTypes.list) {
                    state.listLoading = false;
                } else {
                    state.actionsLoading = false;
                }
            },
            startCall: (state, action) => {
                state.error = null;
                state.status = 'actionPending';
                //  Show Logs
                if (SHOW_LOGS) console.log(action.payload.callType)

                if (action.payload.callType === callTypes.list) {
                    state.listLoading = true;
                } else if (action.payload.callType === callTypes.exercise) {
                    state.exerciseLoading = true;
                } else if (action.payload.callType === callTypes.step) {
                    state.stepLoading = true;
                } else if (action.payload.callType === callTypes.section) {
                    state.sectionLoading = true;
                } else {
                    state.actionsLoading = true;
                }
                state.avatarUrl = ' '
            }

        }
    })
;
