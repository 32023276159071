import axios from "axios";

export const PLANS_URL = "api/plans";

// export const PLANS_URL_ESDA= `${process.env.REACT_APP_ESDA_API}/api/v1/plans`;
const ESDA_API = process.env.REACT_APP_ESDA_API ? process.env.REACT_APP_ESDA_API : ''
export const PLAN_TEMPLATES_URL_ESDA= `${ESDA_API}/api/v1/plans/template`;

// CREATE =>  POST: add a new customer to the server
export function createPlanExercise(planExercise) {
  console.log("CreatePlan: ", planExercise)
  // return axios.post(PLANS_URL, { plan });
  // const processedUrl = configureUrl(planExercise.plan_id)
  return fetch(`${PLAN_TEMPLATES_URL_ESDA}/`,{
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(planExercise),
  }).catch(err=>{
    console.log("ERROR:", err)})
}

// READ
export function getAllExercisesByPlanId(planId) {
  // return axios.get(PLANS_URL);
  // Exception for Url from general PLAN_TEMPLATES_URL_ESDA
  const processedUrl = `${ESDA_API}/api/v1/plans/${planId}/template/`
  console.log({processedUrl})

  return fetch(`${processedUrl}`)
}

export function getPlanExerciseById(planExerciseId) {
  // return axios.get(`${PLANS_URL}/${planId}`);
  const processedUrl = `${PLAN_TEMPLATES_URL_ESDA}/${planExerciseId}`
  console.log({processedUrl})
  return fetch(`${processedUrl}`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPlanExercises(queryParams) {
  return fetch(`${PLAN_TEMPLATES_URL_ESDA}/find`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(queryParams)
      }
  )
// return axios.post(`${PLAN_TEMPLATES_URL_ESDA}/find`,  queryParams );
}
export function updatePlanExerciseOrder(planExercise) {
  // return axios.put(`${PLANS_URL}/${plan.id}`, {plan });
  return fetch(`${PLAN_TEMPLATES_URL_ESDA}`,{
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(planExercise),
  }).catch(err=>{
    console.log("ERROR:", err)})
}
// UPDATE => PUT: update the customer on the server
export function updatePlanExercise(planExercise) {
  // return axios.put(`${PLANS_URL}/${plan.id}`, {plan });
  return fetch(`${PLAN_TEMPLATES_URL_ESDA}/${planExercise.plan_id}`,{
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(planExercise),
  }).catch(err=>{
    console.log("ERROR:", err)})
}

// UPDATE Status
export function updateStatusForPlans(ids, status) {
  return axios.post(`${PLANS_URL}/updateStatusForPlans`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deletePlanExercise(planExerciseId) {
  // return axios.delete(`${PLANS_URL}/${planId}`);
  return fetch(`${PLAN_TEMPLATES_URL_ESDA}/${planExerciseId}`,{
    method: 'DELETE',
    // headers: { 'Content-Type': 'application/json' },
  }).catch(err=>{
    console.log("ERROR:", err)})

}


// DELETE Customers by ids
export function deletePlanExercises(ids) {
  return axios.post(`${PLANS_URL}/deletePlanExercises`, { ids });
}

export function getAllPlanExerciseExercisesByPlanExerciseId(planTemplateId,user) {
  // return axios.get(PLANS_URL);
  const processedUrl = `${ESDA_API}/api/v1/exerc/${planTemplateId}?user_id=${user}`
  console.log({processedUrl})

  return fetch(`${processedUrl}`,{
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).catch(err=>{
    console.log("ERROR:", err)})
}