import React, {useEffect, useState} from "react";
import * as actions from "../../../../redux/planExercises/planExercisesActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import MyCard from "../../UI/MyCard";
import {useRouteMatch} from "react-router-dom";
import MyScrollableList from "../../Exercises/ExercisesList/UI/MyScrollableList";
import {LIST} from "../../Exercises/ExercisesList/mockdata/data";
import classes from "../ExercisesDashboard.module.css";

const ExercisesPlanTemplatesList = () => {

        const [expandedSection, setExpandedSection] = useState('');

        const {params} = useRouteMatch();
        const planId = params.planId;

        const {currentState, listLoading: loadingExercises} = useSelector(
            (state) => ({currentState: state.planExercises}),
            shallowEqual
        );
        const {id: userId} = useSelector((state) => (state.auth.user), shallowEqual);

        // const { totalCount, entities, listLoading } = currentState;
        const {entities} = currentState;

        const dispatch = useDispatch();

        const expandSectionHandler = (section) => {
            setExpandedSection(section)
        }

        useEffect(() => {
            // dispatch(actions.fetchPlans(plansUIProps.queryParams));
            if (userId && planId && !isNaN(+planId))
                dispatch(actions.fetchPlansFiltered({
                    "plan_id": planId / 1,
                    "patient_id": userId, //"dd5682a6-1610-442e-bba2-7b6b3ff45548",
                    "is_active": false
                }))
        }, [planId, userId])
        console.log({entities})

        return (
            <div className={classes.cardContainer}>

                <MyCard className={classes.card}>
                    <div className={classes.cardContext}>
                        <div>
                            <h2>Ασκήσεις Γλώσσας</h2>
                            <hr style={{borderWidth: "3px"}}/>
                        </div>
                        {!loadingExercises &&
                        (entities&& !entities.COMPREHENSION) && // on changing planTemplate model error between pages
                        <>
                            <MyScrollableList entities={entities}
                                              activeItemId={planId}
                                              title={'Κατανόηση'} // Γλώσσας
                                              emptyMessage={'Δεν υπάρχουν ασκήσεις για αυτό το πλάνο.'}
                                              itemPath={'/exercise/execution'}
                                // height={!expandedSection ? "45%" : (expandedSection === 'lang' ? "70%" : "20%")}
                                              height={"70%"}
                                              onExpandSectionHandler={expandSectionHandler.bind(this, "lang")}
                                // maxHeight={}
                            />
                            <MyScrollableList entities={[]} // LIST['wp']
                                              activeItemId={planId}
                                              title={'Παραγωγή'} // Γλώσσας
                                              emptyMessage={'Δεν υπάρχουν ασκήσεις για αυτό το πλάνο.'}
                                              itemPath={'#'} //  /exercise/execution
                                // height={!expandedSection ? "45%" : (expandedSection === 'prod' ? "70%" : "20%")}
                                              height={"20%"}
                                              onExpandSectionHandler={expandSectionHandler.bind(this, "prod")}
                                // maxHeight={}
                            />
                        </>}
                    </div>

                </MyCard>

            </div>

        );
    }
;

export default ExercisesPlanTemplatesList;