import React from "react";
import MyCard from "../../UI/MyCard";
import {Link} from "react-router-dom";
import {LIST} from "../../Exercises/ExercisesList/mockdata/data";
import MyScrollableList from "../../Exercises/ExercisesList/UI/MyScrollableList";
import classes from "../ExercisesDashboard.module.css";
import isAuthorized from "../../../helpers/AuthorizedFunction";

const ExercisesPlanTemplatesList = (props) => {
    const isNotPatient = isAuthorized(['therapist', 'admin'])

    console.log(props.type)
        return (
            <div className={classes.cardContainer} >

                <MyCard className={classes.card}>
                    <div className={classes.cardContext}>
                        <div>
                            <h2>Ασκήσεις Ομιλίας</h2>
                            <hr style={{borderWidth:"3px"}} />
                        </div>
                        <MyScrollableList entities={[]} //  LIST['spEp']
                            // activeItemId={planId}
                                          title={'Επιτονισμός'} // Γλώσσας
                                          // emptyMessage={'Δεν υπάρχουν ασκήσεις για αυτό το πλάνο.'}
                                          itemPath={'#'}
                                          height={'15%'}
                                          onExpandSectionHandler={()=>{}}
                            // maxHeight={}
                        />
                        <MyScrollableList entities={[]} //  LIST['spEp']
                            // activeItemId={planId}
                            //               title={'Επιτονισμός'} // Γλώσσας
                                          emptyMessage={'Δεν υπάρχουν ασκήσεις για αυτό το πλάνο.'}
                                          itemPath={'#'}
                                          height={'35%'}
                                          onExpandSectionHandler={()=>{}}
                            // maxHeight={}
                        />
                        <MyScrollableList entities={[]} //  LIST['spEp']
                            // activeItemId={planId}
                                          title={'Αναπνοή - Φώνηση'} // Γλώσσας
                                          emptyMessage={'Δεν υπάρχουν ασκήσεις για αυτό το πλάνο.'}
                                          itemPath={'#'}
                                          height={'45%'}
                                          onExpandSectionHandler={()=>{}}
                            // maxHeight={}
                        />
                        {isNotPatient &&
                        <div className={'d-flex flex-column  justify-content-center '} style={{height: "45%"}}>
                            <h4><u>
                                Αναπνοής - Φώνησης
                            </u></h4>
                            <div className={'overflow-auto'}>
                                {LIST['speech'].map(plTemplate => { // LIST['speech']
                                    return <div key={plTemplate.id}
                                                className={'d-flex flex-column justify-content-between'}
                                                style={{
                                                    border: "1px dotted black",
                                                    margin: "0.8rem 0",
                                                    padding: "0.5rem",
                                                    textAlign: "start"
                                                }}
                                        // backgroundColor:plTemplate.isCompleted ? "lightgoldenrodyellow" : ""
                                    >
                                        {/*<h4>#{plTemplate.label} - {plTemplate.template_category && plTemplate.template_category.label}</h4>*/}
                                        <h4>{plTemplate.template_category && plTemplate.template_category.label}</h4>
                                        {plTemplate.sections.map(section => {
                                            return <div key={section.id}
                                                        className={'d-flex flex-grow-1 flex-row justify-content-between '}
                                                        style={{
                                                            borderTop: "1px dashed black",
                                                            padding: "0.5rem",
                                                            backgroundColor: section.isCompleted ? "#e8f1e8" : ""
                                                        }}
                                                // backgroundColor:plTemplate.isCompleted ? "lightgoldenrodyellow" : ""
                                            >
                                                <h5> {section.id}{section.label && ". " + section.label}</h5>
                                                {section.isAvailable &&
                                                <Link className={'btn btn-danger'}
                                                      style={{padding: "0.3rem 0.4rem"}}
                                                      to={`/exercise/execution/${plTemplate.id}/${section.id}`}
                                                    // to={`/exercise/plans/${planId}/${plTemplate.id}/${plTemplate.template_category && plTemplate.template_category.id}`}
                                                >
                                                    <i className={"fa fa-angle-double-right"}/>
                                                </Link>
                                                }
                                                {!section.isAvailable &&
                                                <button className={'btn btn-secondary'}
                                                        style={{padding: "0.3rem 0.4rem"}}
                                                        disabled={true}
                                                >
                                                    <i className={"fa fa-angle-double-right"}/>
                                                </button>
                                                }
                                            </div>
                                        })}

                                    </div>
                                })}
                                {LIST['speech'].length === 0 && <h5> This Plan has not configured exercises</h5>}
                            </div>
                        </div>}
                    </div>
                    </MyCard>

            </div>

        );
    }
;

export default ExercisesPlanTemplatesList;