import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {plansSlice} from "./plans/plansSlice";
import {patientsSlice} from "./patients/patientsSlice";
import {exercisesSlice} from "./exercises/exercisesSlice";
import {planExercisesSlice} from "./planExercises/planExercisesSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    customers: customersSlice.reducer,
    patients: patientsSlice.reducer,
    plans: plansSlice.reducer,
    planExercises: planExercisesSlice.reducer,
    exercises: exercisesSlice.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
