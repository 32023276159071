import React from 'react';
import {Link} from "react-router-dom";

const WidgetTableHeader = (props) => {
    return (
        <>
            <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">
                    {props.title}
                  </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
                    {props.subtitle}
                  </span>
                </h3>

                <div className="card-toolbar">
                    {props.buttonTitle &&
                    <Link className={'btn btn-primary font-weight-bolder font-size-sm'} to={props.buttonLink}>
                        {props.buttonTitle}
                    </Link>}
                </div>
            </div>
        </>
    );
};

export default WidgetTableHeader;