import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {DashboardPage} from "./pages/DashboardPage";
import {ProfilePage} from "./pages/ProfilePage";
import {PrivateRoute} from "./helpers/PrivateRoute";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ECommercePage = lazy(() => import("./modules/ECommerce/pages/eCommercePage"));
const PatientsPage = lazy(() => import("./pages/PatientsPage"));
const ExercisesPage = lazy(() => import("./pages/ExercisesPage"));
const ExerciseSamplesPage = lazy(() => import("./pages/ExerciseSamplesPage"));
const PlanViewPage = lazy(() => import("./pages/PlanViewPage"));
const PlansPage = lazy(() => import("./pages/PlansPage"));
const ResultsPage = lazy(() => import("./pages/ResultsPage"));

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }

                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/profile" component={ProfilePage}/>

                <PrivateRoute path="/patients/:patientId/plans/:planId/schedule"  component={PlanViewPage} roles={['therapist']}/>
                <PrivateRoute path="/patients/:patientId/plans"  component={PlansPage} roles={['therapist']}/>
                <PrivateRoute path="/patients"  component={PatientsPage} roles={['therapist']}/>

                <Route path="/exercise"  component={ExercisesPage}/>
                <Route path="/exercise-samples"  component={ExerciseSamplesPage}/>

                <Route path="/results" component={ResultsPage}/>

                <Route path="/public-plans/:planId/schedule"  component={PlanViewPage}/>
                <Route path="/public-plans"  component={PlansPage}/>
                {/*<Route path="/e-commerce"  component={SpeechPage}/>*/}
                <Route path="/e-commerce"  component={ECommercePage}/>
                <PrivateRoute path="/calendar" component={ProfilePage}  roles={['patient']} />

                {/*// false*/}
                <Redirect to="error/error-v1"/>

                {/*correct*/}
                {/*<Redirect to="/error/error-v1"/>*/}

            </Switch>
            <ToastContainer autoClose={false} />
        </Suspense>
    );
}
