import * as PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import classes from "./MyScrollableList.module.css";
import {ListGroup} from "react-bootstrap";

const MyScrollableMenuList = (props) => {
    return <ListGroup className={`${classes.card} ${classes['card-menu']}`}>
        <h2><u> {props.title}</u></h2>
        <div className={classes.listArea}>
            {props.entities && !(props.entities.length > 0) && <div>{props.emptyMessage}</div>}

            {props.entities && (props.entities.length > 0) && props.entities.map(item => {
                return <div key={item.id}
                            className={`d-flex flex-row  justify-content-between align-items-center ${classes.item}`}
                            style={{
                                borderLeft: "5px solid cyan",
                                textAlign: "left",
                                margin: "0.6rem 0.2rem",
                                padding: "0.5rem",
                                backgroundColor: (item.id === +props.activeItemId) ? "lightcyan" : "#f5f5fd"
                            }}
                >
                    <h4  style={{ padding: "2px"}} >{item.title}</h4>
                    <Link className={'btn btn-outline-danger'}
                          style={{padding: "0.3rem 0.4rem"}}
                          to={`${props.itemPath}/${item.id}`}
                    >
                        <i className={"fa fa-angle-double-right"}/>
                    </Link>
                </div>
            })}
        </div>
    </ListGroup>;
}
export default MyScrollableMenuList;