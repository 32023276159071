import React, {useRef, useEffect, useState} from "react";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import {useKeycloak} from "@react-keycloak/web";
import {getEsdaUserByKeycloakId, getUserByUsername} from "../../../api/patientsCrud";
import {toast} from "react-toastify";

function AuthInit(props) {
    const didRequest = useRef(false);
    const didLogin = useRef(false);
    const dispatch = useDispatch();
    const [showSplashScreen, setShowSplashScreen] = useState(true);

    const {authToken} = useSelector(
        ({auth}) => ({
            authToken: auth.authToken,
        }),
        shallowEqual
    );

    const {keycloak, initialized} = useKeycloak();
    const isKeycloakAuthenticated = keycloak.authenticated
    // console.log("AuthInit", {keycloak, datetime: Date.now()})
    console.log("AUTH",{keycloak, initialized})

    const requestUser = async () => {
        try {
            if (!didRequest.current) {
                // const { data: user } = await getUserByToken();
                const user = await keycloak.loadUserProfile()
                const response = await getEsdaUserByKeycloakId(keycloak && keycloak.idTokenParsed.sub)
                    .catch(error=>{
                        console.log(`DB Error: `,{error})
                        error && toast.error(`Υπήρξε πρόβλημα στην επικοινωνία με την Βάση. Η εφαρμογή μπορεί να μην λειτουργεί σωστά`,{theme: "dark"})
                        return {}
                    })
                const esdaProfile = response.status === 200 ? response.data : undefined;

                console.log({user,id:keycloak.idTokenParsed})
                dispatch(auth.actions.fulfillUser({...user,id:keycloak && keycloak.idTokenParsed.sub, esdaProfile}));
                // dispatch(auth.actions.fulfillUser(keycloak.profile));

                // await getUserByUsername(user.username).then(response => {
                //     console.log({response})
                //     if (response && response.data && response.data.length > 0) {
                //         // dispatch(auth.actions.fulfillUser(user));
                //         dispatch(auth.actions.fulfillUser(response.data[0]));
                //     }
                // })
            }
        } catch (error) {
            console.error(error);
            // if (!didRequest.current) {
            //     dispatch(props.logout());
            // }
        } finally {
            setShowSplashScreen(false);
        }

        return () => (didRequest.current = true);
    };

    useEffect(() => {
        if (initialized && !isKeycloakAuthenticated && !didLogin.current) {
            keycloak.login().then(() => {
                didLogin.current = true
            })
        }
        if (initialized && isKeycloakAuthenticated && !didRequest.current) {
            dispatch(auth.actions.login(keycloak.token));

            // We should request user by authToken before rendering the application
            requestUser();
            didRequest.current=true
        }
    }, [initialized , isKeycloakAuthenticated]);

    return <>
            {showSplashScreen ? <LayoutSplashScreen/> : <>{props.children}</>}
    </>;
}

export default AuthInit;
