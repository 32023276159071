import {createSlice} from "@reduxjs/toolkit";

const initialPatientsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  patientForEdit: undefined,
  newPatientCreated: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState: initialPatientsState,
  reducers: {
    catchError: (state, action) => {
      // state.error = `${action.type}: ${action.payload.error}`;
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    patientFetched: (state, action) => {
      state.actionsLoading = false;
      state.patientForEdit = action.payload.patientForEdit;
      state.error = null;
    },
    // findCustomers
    patientsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;

      // if(state.newPatientCreated){
      //   state.entities.unshift(state.newPatientCreated)
      //   state.newPatientCreated=undefined
      // }
    },
    // createCustomer
    patientCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.newPatientCreated=action.payload.patient;
      if(state.entities)
      {
        state.entities.push(action.payload.patient)
      }else{
        state.entities= [];
        state.entities.push(action.payload.patient)

      }
    },
    // updateCustomer
    patientUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities && state.entities.length > 0 && state.entities.map(entity => {
        if (entity.keycloak_id === action.payload.id) { //id === action.payload.id
          // return action.payload.patient;
          return {...entity , ...action.payload.patientData};
        }
        return entity;
      });
    },
    // deleteCustomer
    patientDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // customersUpdateState
    patientsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
