// { template_id, items:  [ { sectionId: A, isCompleted}, …]  }

export const LIST = {
    wp:[
        {id: 11, template_category:{id:13,label:"T13"}, isCompleted:false, isAvailable:true},
        {id: 23, template_category:{id:13,label:"T16"}, isCompleted:false},
        {id: 24, template_category:{id:13,label:"T16"}, isCompleted:false},
        {id: 47, template_category:{id:13,label:"T21"}, isCompleted:false , ratio:'80%'}
    ],
    intonation_instructions:{},
    spEp:[
        {id: "SP3_A", template_category:{id:"SP3",label:"Οδηγίες"}, "isCompleted": true, "isAvailable":true },
        {id: 453, template_category:{id:"SP3_A",label:" Αντίληψη"}, isCompleted:false, isAvailable:false},
        {id: "55", template_category:{id:"SP3_Β",label:"Παραγωγή"}, "isCompleted": false, "isAvailable":false, },
        {id: "66", template_category:{id:"SP3_Β",label:" Αντίληψη"}, "isCompleted": false, "isAvailable":false,
            "sections":[
                { "id": "3_Instructions", "label": "Οδηγίες", }
                // { "id": "2A", "isCompleted": true, "isAvailable":true},
                // { "id": "2_B", "isCompleted": true, "isAvailable":true}
            ]},
    ],
    speech:[
        {id: "SP1", template_category:{id:"SP1",label:"Κανονικό ύψος φωνής-φωνήεν"}, isCompleted:false, sections:[
                { "id": "SP1_A", "label": "Αξιολόγηση", "isCompleted": true, "isAvailable":true},
                { "id": "SP1_B", "label": "", "isCompleted": true, "isAvailable":true},
                { "id": "SP1_C", "label": "", "isCompleted": true, "isAvailable":true},
                { "id": "SP1_D", "label": "", "isCompleted": true, "isAvailable":true},
                { "id": "SP1_E", "label": "", "isCompleted": false, "isAvailable":true},
                { "id": "SP1_F", "label": "", "isCompleted": false, "isAvailable":false}
            ]},
        {id: "SP2", template_category:{id:"SP2",label:"Αλλαγή ύψους φωνής-φωνήεν "}, isCompleted:false, "sections":[
                { "id": "SP2_A", "isCompleted": true, "isAvailable":true},
                { "id": "SP2_B", "isCompleted": false, "isAvailable":true},
                { "id": "SP2_C", "isCompleted": false, "isAvailable":true},
                { "id": "SP2_D", "isCompleted": false, "isAvailable":true},
                { "id": "SP2_E", "isCompleted": false, "isAvailable":true}
            ]},
    ]
}