import React from "react";
import DashboardTherapist from "../components/Dashboard/DashboardTherapist";
import ExercisesDashboard from "../components/DashboardPatient/ExercisesDashboard";
import isAuthorized from "../helpers/AuthorizedFunction";

export function DashboardPage() {
    const isNotPatient = isAuthorized(['therapist', 'admin'])

    return (<>
            {isNotPatient && <DashboardTherapist/>}
            {!isNotPatient && <ExercisesDashboard/>
            }
        </>


    );
}
