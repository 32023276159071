import * as requestFromServer from "../../app/api/plansCrud";
import {plansSlice, callTypes} from "./plansSlice";
import {getEsdaUserByKeycloakId} from "../../app/api/patientsCrud";

const {actions} = plansSlice;

export const fetchPlans = (queryParams) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return requestFromServer
        .findPlans(queryParams)
        // .getAllPlans(queryParams)
        .then(async response => {
            console.log({response})
            const data = await response.json()
            console.log(data)
            const {  totalCount ,data: entities } = data; // totalCount,
            // const totalCount = (entities && entities.data ) ?? 0;
            dispatch(actions.plansFetched({ totalCount, entities }));
            // dispatch(actions.plansFetched({totalCount: data.length, entities: data},));
        })
        .catch(error => {
            error.clientMessage = "Can't find customers";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        });
};

export const fetchPlan = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    if (!id) {
        return dispatch(actions.planFetched({planForEdit: undefined}));
    }
    return requestFromServer
        .getPlanById(id)
        .then(async response => {
            const data = await response.json()
            // const plan = response.data;
            // console.log(data)

            dispatch(actions.planFetched({planForEdit: data}));
        })
        .catch(error => {
            error.clientMessage = "Can't find customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const deletePlan = id => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .deletePlan(id)
        .then(response => {
            dispatch(actions.planDeleted({id}));
        })
        .catch(error => {
            error.clientMessage = "Can't delete customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};
export const removePlanLink = (id, patientKeycloakId) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getPlanById(id)
        .then(async response => {
            // dispatch(actions.planDeleted({id}));
            const data = await response.json()
            const {id,plan_type, dataPatients} = data;
            // const patientToBeRemoved = dataPatients.find(patient=>patient.keycloak_id === patientKeycloakId)
            const newDataPatients = dataPatients.filter(patient=>patient.keycloak_id !== patientKeycloakId).map(patient=>({id:patient.id}))
            // console.log({data, patientToBeRemoved,newDataPatients})

            await requestFromServer.updatePlan({...data, dataPatients: newDataPatients})
        })
        .catch(error => {
            error.clientMessage = "Can't delete customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};
export const assignPatientToPlan = (planId, patientId) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getPlanById(planId)
        .then(async response => {
            // dispatch(actions.planDeleted({id}));
            const data = await response.json()
            const {id,plan_type, dataPatients} = data;
            const oldDataPatients = dataPatients.map(patient=>({id:patient.id}))
            console.log({data, oldDataPatients})
            const updatedPlan = {...data, dataPatients: [...oldDataPatients, {id: patientId}]}
            await requestFromServer.updatePlan(updatedPlan)
                .then(() => {
                    dispatch(actions.planUpdated({plan:updatedPlan}));
                })
        })
        .catch(error => {
            error.clientMessage = "Can't update plan LINKS";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const createPlan = planData => async dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    console.log('planForCreation')
    // new entry for  Personal Plan
    let planForCreation = planData
    if (planData && planData.patient_id && !planData.id){
        const response = await getEsdaUserByKeycloakId(planData.patient_id)
            .catch(e => {
                console.log(e)
                return {}
            })
        const esdaUser = response.status === 200 ? response.data : undefined;
        console.log({esdaUser})
        planForCreation = {...planData, dataPatients: [{id: esdaUser.id}]}
    }
    return requestFromServer
        .createPlan(planForCreation)
        .then( async response => {
            // const res = await response.text()
            // const res = await response.json()     // replace text()
            // console.log({res})
            // if (!res) {
            //     console.log("Request failed! there is empty body. PlanCreated Json missing");
            // }
            const data = await response.json()
            console.log(data)
            // const {newPlan} = data;
            dispatch(actions.planCreated({newPlan:data}));
        })
        .catch(error => {
            error.clientMessage = "Can't create customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const updatePlan = plan => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .updatePlan(plan)
        .then(() => {
            dispatch(actions.planUpdated({plan}));
        })
        .catch(error => {
            error.clientMessage = "Can't update customer";
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};
