import axios from "axios";
import {EXERCISE_URL} from "../../../api/exercises";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const SET_ATLAS_TOKEN_URL = `http://sso.atlas.esdalab.ece.uop.gr/v1/auth`;

export function login(email, password) {
    return axios.post(LOGIN_URL, {email, password});
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, {email, fullname, username, password});
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, {email});
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}

export function getTokenByGrant(grant) {
    // return axios.post(SET_ATLAS_TOKEN_URL, { grant });
    return fetch(`${SET_ATLAS_TOKEN_URL}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"grant":grant}),
        // mode: 'no-cors'
    })
}