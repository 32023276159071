import React, {useEffect, useState} from "react";
import * as actions from "../../../../redux/plans/plansActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import MyCard from "../../UI/MyCard";
import {useLocation, useRouteMatch} from "react-router-dom";
import {findPlans} from "../../../api/plansCrud";
import MyScrollableMenuList from "../../Exercises/ExercisesList/UI/MyScrollableMenuList";
import classes from "../ExercisesDashboard.module.css";

const ExercisesPlansList = () => {

        const [publicPlansList, setPublicPlansList] = useState([])

        const location = useLocation();
        const route = useRouteMatch();
        const {params, path} = route;
        // const planId = params.planId;
        const planId = location.pathname.split("/").pop();

        const {currentState} = useSelector(
            (state) => ({currentState: state.plans}),
            shallowEqual
        );
        const {id} = useSelector((state) => (state.auth.user), shallowEqual);

        // const { totalCount, entities, listLoading } = currentState;
        const {entities} = currentState;

        const dispatch = useDispatch();

        useEffect(() => {
            // dispatch(actions.fetchPlans(plansUIProps.queryParams));
            dispatch(actions.fetchPlans({filter: {patient_id: id, plan_type:1}}));
            findPlans({filter: {patient_id: id, plan_type:0}})
                // .getAllPlans(queryParams)
                .then(async response => {
                    console.log({response})
                    const data = await response.json()
                    console.log({data})
                    if (data && data.data)
                        setPublicPlansList(data.data)
                })
                .catch(error => {
                    console.log({error})
                });
        }, [])
        // console.log({entities, route, location}, planId)

        return (
            <div className={classes.plansSection}
            >

                <MyCard>
                    <div className='d-flex flex-column flex-grow-1' style={{height: "100%"}}>
                        {/*,maxHeight:"800px"*/}
                        <MyScrollableMenuList entities={entities}
                                              activeItemId={planId}
                                              title={'Πλάνα'}
                                              emptyMessage={'Δεν υπάρχουν πλάνα για αυτόν το χρήστη'}
                                              itemPath={path}
                            // maxHeight={}
                        />

                        {publicPlansList && publicPlansList.length > 0 &&
                        <MyScrollableMenuList entities={publicPlansList}
                                              activeItemId={planId}
                                              title={'Γενικά Πλάνα'}
                                              emptyMessage={'Δεν υπάρχουν πλάνα για αυτόν το χρήστη'}
                                              itemPath={path}

                        />
                        }
                    </div>
                </MyCard>

            </div>

        );
    }
;

export default ExercisesPlansList;