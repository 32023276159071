import React  from 'react';
import styles from "./Input.module.css";

// const Input = React.forwardRef((props, ref) => {
// <input ref={ref}  {...props.input} />

const Input = (props) => {

    // const [value, setValue] = useState(props.value);

    // console.log(props.value, props.label);

    // const valueChangeHandler = (event) => {
    //     setValue(event.target.value);
    // };

    const highlightInvalid = props.hasError ;//&& props.input.required;
    const classes = highlightInvalid ? styles.invalid : "form-group mb-3"
    return (
        <div className={classes}>
            <label className="form-label" htmlFor={props.input.id}>{props.label}</label>
            <input {...props.input}
                   defaultValue={props.value}
                // value={value}
                // onChange={valueChangeHandler}
                   onChange={props.onChange}
                   onBlur={props.onBlur}
                   className="form-control"
            />
            {highlightInvalid && (
                <p className="text-danger pl-1 " title={props.error}>*Υποχρεωτικό Πεδίο!</p>
            )}
        </div>
    );
};
// });

export default Input;
